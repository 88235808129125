import { throttle, debounce } from "throttle-debounce";
import React, { Component, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import i18n from 'i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { localModel } from './localModel';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ZoomToFitIcon from '@material-ui/icons/ZoomOutMap';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import history from './history';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AutoComplete from './AutoComplete';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import moment from 'moment';
import 'moment/locale/es';

import ListIcon from '@material-ui/icons/ViewList';
import EventIcon from '@material-ui/icons/Event';
import MapIcon from '@material-ui/icons/LocationOn';
import GalleryIcon from '@material-ui/icons/ViewModule';
import DiagramIcon from '@material-ui/icons/DeviceHub';

import FilterListIcon from '@material-ui/icons/FilterList';
import MemoryIcon from '@material-ui/icons/Memory';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Map, Marker, TileLayer, ImageOverlay } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import FormControlLabel from '@material-ui/core/FormControlLabel';

// BUG https://github.com/Leaflet/Leaflet/issues/4968
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import { refreshPersonalization, refreshModels } from './refresh';

import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import Barcode from './Barcode';

import createEngine, { 
    DefaultLinkModel, 
    DefaultNodeModel,
    DiagramModel,
    DagreEngine,
} from '@projectstorm/react-diagrams';

import {
    CanvasWidget
} from '@projectstorm/react-canvas-core';

import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import CloseIcon from '@material-ui/icons/Close';

import { AdvancedLinkFactory, AdvancedPortModel } from "./ArrowLink";

import LinearProgress from '@material-ui/core/LinearProgress';

import ExcelExport, { alignment, defaultDataType } from 'export-xlsx';

let idByName = {};

const DnDCalendar = withDragAndDrop(Calendar)

const engine = createEngine();
engine.getLinkFactories().registerFactory(new AdvancedLinkFactory());

L.Marker.prototype.options.icon = L.icon({
	iconRetinaUrl,
	iconUrl,
	shadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41]
});

const localizer = momentLocalizer(moment);

const styles = theme => ({
	spacer: {
		flex: "1 1 1%",
	},
	checkbox: {
		width: "1px",
	},
	fab: {
		position: 'fixed',
		zIndex: 1000,
		bottom: theme.spacing.unit * 4 + 50,
		right: theme.spacing.unit * 4,
	},
	fab2: {
		position: 'fixed',
		zIndex: 1000,
		bottom: theme.spacing.unit * 4 + 122,
		right: theme.spacing.unit * 4,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
	},
	canvasWidget: {
		minHeight: "calc(100vh - 176px)",
	},
	table: {
		minWidth: 700,
	},
	tableHeader: {
		whiteSpace: "nowrap",
	},
	tableRow: {
		cursor: "pointer",
	    "&:nth-of-type(odd)": {
	        backgroundColor: theme.palette.background.default,
	    },		
	    /*
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.07)",
		},
		*/
	},
	editionDisabledTableRow: {
		cursor: "default",
	    "&:nth-of-type(odd)": {
	        backgroundColor: theme.palette.background.default,
	    },		
	    /*
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.07)",
		},
		*/
	},
	toolbar: {
	},
	toolbar2: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.main,
	},
	actionToolbar: {
		flex: "0 0 auto",
	},
	action: {
		color: theme.palette.secondary.contrastText,
		"&$disabledAction": {
			color: theme.palette.secondary.light,
		}
	},
	expandMoreButton: {
		margin: theme.spacing.unit * 2,
	},
	disabledAction: {
	},
	noData: {
		flex: 1,
		margin: theme.spacing.unit * 2,
	},
	input: {
		margin: theme.spacing.unit,
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	anchor: {
		color: theme.palette.text.primary,
		textDecoration: "none",
		whiteSpace: "nowrap",
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		marginRight: theme.spacing.unit * 2,
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing.unit * 3,
			width: 'auto',
		},		
	},
	toggleContainer: {
		display: "inline-flex",
		margin: theme.spacing.unit,
	},
	calendar: {
		minHeight: "700px",
		height: "calc(100vh - 176px)",
		padding: theme.spacing.unit * 2,
	},
	bigCalendar: {
		fontFamily: "Roboto, Helvetica, Arial, sans-serif",
	},
	previewDialogPaper: {
		minHeight: "95vh",
		maxHeight: "95vh",
		minWidth: "95vw",
		maxWidth: "95vw",
	},
	dialogContent: {
		display: "flex",
		flexGrow: "1",
		paddingTop: "16px",
	},
	docViewer: {
		display: "flex",
		flexGrow: "1",
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
	},
	iframe: {
		width: '100%',
		minHeight: "300px",
		border: "none",
		// overflow: hidden - Es imposible que un iframe muestre algo flotante sobre otro (sería un problema de seguridad)
		// Otra cosa es que se coja el contenido del iframe y se inyecte en un div, con javascript.
	},
	hover: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.07)",
		},
	},
	imageHover: {
		cursor: "pointer",
		"&:hover": {
			borderColor: theme.palette.secondary.main,
			borderWidth: "3px",
			borderStyle: "solid",
		},
	},
});

const BlockPageScroll = ({ children }) => {
	const scrollRef = useRef(null)
	useEffect(() => {
		const scrollEl = scrollRef.current
		scrollEl.addEventListener('wheel', stopScroll)
	    return () => scrollEl.removeEventListener('wheel', stopScroll)
	}, [])
	const stopScroll = e => e.preventDefault()
	return (
			<div ref={scrollRef}>
				{children}
			</div>
	)
}

class EntityList extends Component {
	
	dagreEngine: DagreEngine;
	
	constructor(props) {
		super(props);
		// console.log(">> EntityList.constructor");
		
		this.dagreEngine = new DagreEngine({
			graph: {
				rankdir: "LR", // T=top, B=bottom, L=left, R=right (TB, BT, LR, RL)
				ranker: "longest-path", // network-simplex, tight-tree, longest-path
				marginx: 25,
				marginy: 25,
				nodesep: 50,
				edgesep: 10,
				ranksep: 50,
				acyclicer: undefined, // "greedy",
			},
			includeLinks: true
		});		
		
		let entityLocalModel = localModel.entities[props.entity]
		let localAttributes = Object.values(entityLocalModel.attributes);
		
		if (this.props.context.state[this.props.entity] == null) {
			this.props.context.state[this.props.entity] = {};
		}
		if (this.props.context.state[this.props.entity].mode == null) {
			this.props.context.state[this.props.entity].mode = (localAttributes.filter(attribute => attribute.imageInGallery).length > 0 ? "gallery" : (localAttributes.filter(attribute => attribute.pointInMap).length > 0 ? "table" : "table"));
		}
		if (this.props.context.state[this.props.entity].basicFiltersEnabled == null) {
			this.props.context.state[this.props.entity].basicFiltersEnabled = false;
		}
		if (this.props.context.state[this.props.entity].intelligenceEnabled == null) {
			this.props.context.state[this.props.entity].intelligenceEnabled = false;
		}
		if (this.props.context.state[this.props.entity].basicFilters == null) {
			this.props.context.state[this.props.entity].basicFilters = {};
		}
		if (this.props.context.state[this.props.entity].orderBy == null) {
			this.props.context.state[this.props.entity].orderBy = null;		
		}
		if (this.props.context.state[this.props.entity].orderDirection == null) {
			this.props.context.state[this.props.entity].orderDirection = "asc";		
		}
		
		Object.keys(entityLocalModel.attributes)
				.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
				.forEach(attributeName => {
					if (this.props.context.state[this.props.entity].basicFilters[attributeName] == null) {
						this.props.context.state[this.props.entity].basicFilters[attributeName] = true;
					}
				});
		
		this.state = {
			currentEntity: null,
			data: null,
			deleteConfirmationDialogOpened: false,
			scrollEnabled: true,
			limit: 100,
			latitude: 40.529179,
			longitude: -3.651276,
			previewOpen: false,
		};
		
		this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
		this.handleAnchorClick = this.handleAnchorClick.bind(this);
		this.handleActionClick = this.handleActionClick.bind(this);
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleCellClick = this.handleCellClick.bind(this);
		this.handleNewFromCalendar = this.handleNewFromCalendar.bind(this);
		this.handleNewClick = this.handleNewClick.bind(this);
		this.handleViewClick = this.handleViewClick.bind(this);
		this.handleDownloadClick = this.handleDownloadClick.bind(this);
		this.handleExportToCSVClick = this.handleExportToCSVClick.bind(this);
		this.handleEditFromCalendar = this.handleEditFromCalendar.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSortClick = this.handleSortClick.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.handleClearCriteriaClick = this.handleClearCriteriaClick.bind(this);
		
		this.handleChangeMode = this.handleChangeMode.bind(this);
		this.handleToggleBasicFilters = this.handleToggleBasicFilters.bind(this);
		this.handleToggleIntelligence = this.handleToggleIntelligence.bind(this);
		
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.handleBasicFilterCheckboxChange = this.handleBasicFilterCheckboxChange.bind(this);
		
		this.handleDiagramEvent = this.handleDiagramEvent.bind(this);
		
		this.expandMore = this.expandMore.bind(this);

		this.getErrorMessage = this.getErrorMessage.bind(this);
		
		this.eventStyleGetter = this.eventStyleGetter.bind(this);
		
		this.refreshDataThrottled = throttle(500, this.refreshData);
		this.handleDeleteDebounced = debounce(3000, true, this.handleDelete);
		
		this.searchInput = React.createRef();
		
		// TODO Habrá que poner una propiedad enableScroll para habilitar esto,
		// en los detalles estará deshabilitado.
		// TODO También habrá un botón que podrás pulsar o que saldrá después de
		// varios scrolls (como en twitter).
		/* Se deshabilita para facilitar el acceso al scroll horizontal
		if (this.props.main) {
			window.addEventListener("scroll", this.updateDimensions.bind(this));
		}
		*/
	}
	
	// Event handlers
	updateDimensions(event) {
		if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 1) {
			if (this.state.scrollEnabled && this.props.context.state[this.props.entity] != null && (this.props.context.state[this.props.entity].mode == 'table' || this.props.context.state[this.props.entity].mode == 'gallery')) {
				if (this._isMounted) {
					this.setState((state, props) => ({
						limit: Math.min(1000, state.limit + 100),
					}), () => this.refreshDataThrottled());
				}
			}
		}
	}
	
	expandMore() {
		if (this.state.scrollEnabled && (this.props.context.state[this.props.entity].mode == 'table' || this.props.context.state[this.props.entity].mode == 'gallery')) {
			if (this._isMounted) {
				this.setState((state, props) => ({
					limit: Math.min(1000, state.limit + 100),
				}), () => this.refreshDataThrottled());
			}
		}
	}
	
	getErrorMessage(message) {
		let { errorMessages } = this.props.context;
		const { t } = this.props;
		
		const model = this.props.context.model;
		const entityModel = model.entities[this.props.entity];
		
		let hasMessage = false;
		
		for (var i = 0; i < errorMessages.length; i++) {
			let errorMessage = errorMessages[i];
			let name = errorMessage.name;
			let regularExpression = new RegExp(errorMessage.regularExpression);
			
			if (regularExpression.test(message)) {
				hasMessage = true;
				
				let label = t("errorMessages." + name);
				label = label.replaceAll("$schema", entityModel.schema);
				label = label.replaceAll("$entity", entityModel.name);
				
				let matches = message.match(regularExpression);
				for (var j = 1; j < matches.length; j++) {
					label = label.replaceAll("$" + j, matches[j]);
				}
				
				label = label.replace(/\$([0-9]+)/g, function(match, p1) { return matches[Number(p1)] }) 
				label = label.replace(/\$label\(\"([a-zA-Z0-9.]+)\"\)/g, function(match, p1) { return t(p1) })
				
				return label;
			}
		}
		
		if (!hasMessage) {
			return message;
		}
	}
	
	handleToggleBasicFilters(event, mode) {
		this.props.context.state[this.props.entity].basicFiltersEnabled = mode;
		
		this.setState({
			// No se utiliza, pero sirve para forzar el refresco de la pantalla
			basicFiltersEnabled: mode,
		});
	}
	
	handleToggleIntelligence(event, mode) {
		this.props.context.state[this.props.entity].intelligenceEnabled = mode;
		this.setState({
			// No se utiliza, pero sirve para forzar el refresco de la pantalla
			intelligenceEnabled: mode,
			limit: 100,
		}, () => this.refreshDataThrottled());
	}
	
	handleCheckboxChange(event, checked, attributeName) {
		//console.log(">> EntityView.handleCheckboxChange")
		this.setState((state, props) => {
			this.props.context.state[this.props.entity].basicFilters[attributeName] = checked;
			return {
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				basicFilters: this.props.context.state[this.props.entity].basicFilters,
			};
		},  () => this.handleSearchClick(event));
	}
	
	handleBasicFilterCheckboxChange(attributeName) {
		//console.log(">> EntityView.handleBasicFilterCheckboxChange")
		if (this._isMounted) {
			this.setState((state, props) => {
				this.props.context.state[this.props.entity].basicFilters[attributeName] = (this.props.context.state[this.props.entity].basicFilters[attributeName] == null ? true : (this.props.context.state[this.props.entity].basicFilters[attributeName] ? false : null))
				return {
					// No se utiliza, pero sirve para forzar el refresco de la pantalla
					basicFilters: this.props.context.state[this.props.entity].basicFilters,
				};
			});
		}
	}
	
	handleChangeMode(event, mode) {
		if (mode != null) {
			this.props.context.state[this.props.entity].mode = mode;
			this.setState({
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				mode: mode
			});
		}
	}
	
	handleSearchKeyDown(event) {
		if (event.keyCode === 13) {
			this.props.context.state[this.props.entity].searchCriteria = this.searchInput.current.value;
			this.setState({
				limit: 100,
			}, () => this.refreshDataThrottled());
		}
	}
	
	handleSearchClick(event) {
		this.setState({
			limit: 100,
		}, () => this.refreshDataThrottled());
	}
	
	handleClearCriteriaClick(event) {
		let attributes = this.state.attributes;
		const entityLocalModel = localModel.entities[this.props.entity];
		const model = this.props.context.model;
		
		if (this.searchInput.current != null) {
			this.searchInput.current.value = "";
			this.props.context.state[this.props.entity].searchCriteria = this.searchInput.current.value;
		}
		
		attributes
				.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
						|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
				.forEach(attribute => {

					
			if (!attribute.array
					&& attribute.referenceAttributeName !== undefined) {
				if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
					let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
					this.basicFilterRefs[attribute.name].current.select.select.setValue(null, 'select-option');
					this.props.context.state[this.props.entity].basicFilters[attribute.name] = "";
				}
			}
			else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
				if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
					this.basicFilterRefs[attribute.name].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name] = "";
					//this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"] = false; // No refresca
				}
			}
			else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
				if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
					Object.values(this.basicFilterRefs[attribute.name].current.options).forEach(option => option.selected = option.value == "_blank");
					this.props.context.state[this.props.entity].basicFilters[attribute.name] = "_blank";
				}
			}
			else if (!attribute.array
					&& (attribute.type === "INTEGER"
						|| attribute.type === "SMALLINT"
						|| attribute.type === "BIGINT"
						|| attribute.type === "SERIAL"
						|| attribute.type === "DECIMAL"
						|| attribute.type === "DOUBLE_PRECISION"
						|| attribute.type === "REAL"
						|| attribute.type === "MONEY"
						|| attribute.type === "SMALLSERIAL"
						|| attribute.type === "BIGSERIAL")) {
				if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
					this.basicFilterRefs[attribute.name + "From"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = "";
					//this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"] = false; // No refresca
				}
				if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
					this.basicFilterRefs[attribute.name + "To"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = "";
					//this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"] = false; // No refresca
				}
			}
			else if (!attribute.array
					&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
				if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
					this.basicFilterRefs[attribute.name + "From"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = "";
					//this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"] = false; // No refresca
				}
				if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
					this.basicFilterRefs[attribute.name + "To"].current.value = "";
					this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = "";
					//this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"] = false; // No refresca
				}
			}
			else if (!attribute.array && attribute.type == "BOOLEAN") {
				this.props.context.state[this.props.entity].basicFilters[attribute.name] = null;
			}
		});
		this.setState({
			limit: 100,
		}, () => this.refreshDataThrottled());
	}
	
	handleSelectAllClick(event, checked) {
		this.state.data.map(item => item._selected = checked);
		this.setState((state, props) => ({data: state.data}));
	}
	
	go(target, resetTab, entity, startTimestamp, endTimestamp) {
		if (resetTab) {
			let entityLocalModel = localModel.entities[entity]
			let selectedTab = null;
			if (entityLocalModel.tabs != null) {
				let selectedTabItem = Object.values(entityLocalModel.tabs).filter(tab => Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab === tab.id).length > 0).sort((a, b) => a.order - b.order)[0];
				if (selectedTabItem != null) {
					selectedTab = selectedTabItem.id;
				}
			}
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].selectedTab = selectedTab;
		}

		if (startTimestamp != null) {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].startTimestamp = startTimestamp;
		}
		else {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].startTimestamp = null;
		}
		
		if (endTimestamp != null) {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].endTimestamp = endTimestamp;
		}
		else {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].endTimestamp = null;
		}
		
		history.push(target);
	}
	
	handleRowClick(event, item) {
		event.stopPropagation();
		if (!this.props.editionDisabled) {
			this.go("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view", true, this.props.entity);
		}
	}
	
	handleCellClick(event, item, attribute, entityModel, entityLocalModel) {
		if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT"
				&& item[attribute.name] != null) {
			if (item[attribute.name].type == "application/pdf" || item[attribute.name].type == "audio/mpeg" || item[attribute.name].type == "audio/wav" || item[attribute.name].type == "audio/x-wav" || item[attribute.name].type == "video/mp4") {
				event.stopPropagation();
				window.open(this.props.context.baseUrl + "/wopi/files/" + entityModel.schema + "/" + entityModel.name + "/" + item[this.state.keyAttribute.name] + "/" + attribute.name + "/contents?inline=true&access_token=" + this.props.context.accessToken);
			}
			else if (item[attribute.name].type == ""
					|| item[attribute.name].type == "image/bmp"
					|| item[attribute.name].type == "application/vnd.oasis.opendocument.text"
					|| item[attribute.name].type == "application/vnd.oasis.opendocument.spreadsheet"
					|| item[attribute.name].type == "application/vnd.oasis.opendocument.presentation"
					|| item[attribute.name].type == "application/msword"
					|| item[attribute.name].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					|| item[attribute.name].type == "text/htm"
					|| item[attribute.name].type == "text/html"
					|| item[attribute.name].type == "image/jpg"
					|| item[attribute.name].type == "image/jpeg"
				//	|| item[attribute.name].type == "application/pdf"
					|| item[attribute.name].type == "image/png"
					|| item[attribute.name].type == "application/vnd.ms-powerpoint"
					|| item[attribute.name].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
				//	|| item[attribute.name].type == "image/tiff" - No funciona
					|| item[attribute.name].type == "application/vnd.ms-excel"
					|| item[attribute.name].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
				event.stopPropagation();
				this.setState({
					previewOpen: true,
					previewUrl: this.props.context.baseUrl + "/wopi/files/" + entityModel.schema + "/" + entityModel.name + "/" + item[this.state.keyAttribute.name] + "/" + attribute.name + "/contents?inline=true&access_token=" + this.props.context.accessToken,
				});
			}
		}
	}
	
	onPreviewCloseDialog = () => {
		this.setState({
			previewOpen: false,
		});
	};
	
	handleCheckboxClick(event, item) {
		event.stopPropagation();
		item._selected = !item._selected;
		this.setState((state, props) => ({
			data: state.data,
		}));
	}
	
	handleAnchorClick(event, item, attribute) {
		event.preventDefault();
		event.stopPropagation();
		if (!this.props.editionDisabled) {
			const model = this.props.context.model;
			let keyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
			this.go("/admin/" + attribute.referencedKey.entityName + "/" + item[attribute.referenceAttributeName][keyAttribute.name] + "/view", true, attribute.referencedKey.entityName);
		}
	}
	
	handleActionClick(event, item, attribute) {
		event.preventDefault();
		event.stopPropagation();
		if (item[attribute.name] != null && item[attribute.name] != "") {
			
			let url = item[attribute.name];
			url += (url.indexOf("?") != (-1) ? "&" : "?") + "access_token=" + this.props.context.accessToken;
			
			fetch(url, {
				method: "GET",
				redirect: "manual",
			})
			.then(response => {
				let location = response.headers.get("Location");
				
				if (location != null && location != "") {
					if (location.startsWith("http") || location.startsWith("https")) {
						document.location = location;
					}
					else {
						this.go(location);
					}
				}
				else {
					setTimeout(this.refreshDataThrottled(), 500);
				}
			})
			.catch(error => {
				console.log(error);
				setTimeout(this.refreshDataThrottled(), 500);
			});
		}
	}
	
	handleNewFromCalendar(event) {
		this.go("/admin/" + this.props.entity + "/new", true, this.props.entity, event.start, event.end);
	}
	
	handleNewClick(event) {
		if (this.props.filterAttribute == null) {
			this.go("/admin/" + this.props.entity + "/new", true, this.props.entity);
		}
		else {
			this.go("/admin/" + this.props.entity + "/new/" + this.props.filterAttribute + "/" + this.props.filterValue, true, this.props.entity);
		}
	}
	
	handleViewClick(event) {
		this.go("/admin/" + this.props.entity + '/' + this.state.data.filter(item => item._selected)[0][this.state.keyAttribute.name] + "/view", true, this.props.entity);
	}
	
	handleEditFromCalendar(event) {
		if (event.isAllDay) {
			this.go("/admin/" + this.props.entity + '/' + event.event[this.state.keyAttribute.name] + "/edit", true, this.props.entity, new Date(moment(event.start).startOf('day')), new Date(moment(event.end).startOf('day')));
		}
		else {
			this.go("/admin/" + this.props.entity + '/' + event.event[this.state.keyAttribute.name] + "/edit", true, this.props.entity, event.start, event.end);
		}
	}
	
	handleEditClick(event) {
		this.go("/admin/" + this.props.entity + '/' + this.state.data.filter(item => item._selected)[0][this.state.keyAttribute.name] + "/edit", true, this.props.entity);
	}
	
	handleDeleteClick(event) {
		this.setState({
			deleteConfirmationDialogOpened: true,
		});
	}
	
	handleDownloadClick(event) {
		this.props.context.showActivityIndicator();
		let filename = null;
		
		const selectedItems = this.state.data.filter(item => item._selected);
		
		fetch(this.props.context.baseUrl + "/data-export?entity=" + this.props.entity + "&" + this.state.keyAttribute.name + "=" + selectedItems.map(item => this.setQuotes(this.state.keyAttribute, item[this.state.keyAttribute.name])).toString(), {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}
	
	handleExportToCSVClick(event) {
		this.props.context.showActivityIndicator();

		//let csvContent = ""; // Se descontinúa la exportación a CSV en favor de la exportación a XLSX, pero se mantiene por si se quiere volver a añadir en el futuro.

		let exportSettings = {
			fileName: this.props.entity.replace('.', '-'),
			workSheets: [
				{
					sheetName: this.props.t('e.' + this.props.entity + '.pluralName'),
					startingRowNumber: 1,
					tableSettings: {
						table1: {
							headerDefinition: [],
						},
					},
				},
			],
		};		
		
		// Copy of refreshData. Update accordingly.
		try {
			const model = this.props.context.model;
			
			const entityModel = model.entities[this.props.entity];
			const entityLocalModel = localModel.entities[this.props.entity];
			
			let attributes = Object.values(entityModel.attributes)
					.filter(attribute => (attribute.type !== "CUSTOM_TYPE" || entityLocalModel.attributes[attribute.name].type == "DOCUMENT") 
							&& entityLocalModel.attributes 
							&& entityLocalModel.attributes[attribute.name] 
							&& (
									entityLocalModel.attributes[attribute.name].list === undefined 
									|| entityLocalModel.attributes[attribute.name].list
									|| entityLocalModel.attributes[attribute.name].pointInMap
									|| entityLocalModel.attributes[attribute.name].imageInGallery
									|| entityLocalModel.attributes[attribute.name].basicFilter
							)
					);
			
			Object.values(entityModel.references)
					.filter(reference => { 
						//console.log("reference:");
						//console.log(reference);
						return entityModel.attributes[reference.name]
								&& (entityLocalModel.attributes[reference.referenceAttributeName] === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].list === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].list
								|| entityLocalModel.attributes[reference.referenceAttributeName].basicFilter);
					})
					.forEach(reference => {
						attributes.push({
							name: reference.name,
							entityName: reference.entityName,
							referenceAttributeName: reference.referenceAttributeName,
							attributes: reference.attributes,
							referencedKey: reference.referencedKey,
							type: entityModel.attributes[reference.name].type,
						});
					});
			
			attributes = attributes.filter(attribute => this.props.filterAttribute === undefined || attribute.name !== this.props.filterAttribute);
			
			attributes.sort((a, b) => 
				(	
					entityLocalModel.attributes[a.referenceAttributeName || a.name] == null 
						|| (entityLocalModel.attributes[a.referenceAttributeName || a.name].order == null && entityLocalModel.attributes[a.referenceAttributeName || a.name].orderInList == null) 
							? Infinity 
							: (entityLocalModel.attributes[a.referenceAttributeName || a.name].orderInList == null ? entityLocalModel.attributes[a.referenceAttributeName || a.name].order : entityLocalModel.attributes[a.referenceAttributeName || a.name].orderInList)
				) - (
					entityLocalModel.attributes[b.referenceAttributeName || b.name] == null 
						|| (entityLocalModel.attributes[b.referenceAttributeName || b.name].order == null && entityLocalModel.attributes[b.referenceAttributeName || b.name].orderInList == null) 
							? Infinity 
							: (entityLocalModel.attributes[b.referenceAttributeName || b.name].orderInList == null ? entityLocalModel.attributes[b.referenceAttributeName || b.name].order : entityLocalModel.attributes[b.referenceAttributeName || b.name].orderInList)
				)
			);
			
			let keys = Object.values(entityModel.keys);
			const searchEnabled = keys.filter(key => key.textSearch).length > 0;
			let searchCriteria = null;
			let searchAttributeNames = [];
			
			if (searchEnabled) {
				if (this.props.context.state == null) {
					this.props.context.state = {};
				}
				if (this.props.context.state[this.props.entity] == null) {
					this.props.context.state[this.props.entity] = {};
				}
				if (this.searchInput.current != null && this.searchInput.current.value != null) {
					this.props.context.state[this.props.entity].searchCriteria = this.searchInput.current.value;
				}
				if (this.props.context.state[this.props.entity].searchCriteria == null) {
					this.props.context.state[this.props.entity].searchCriteria = "";
				}
				searchCriteria = (this.props.context.state[this.props.entity].searchCriteria != null ? this.props.context.state[this.props.entity].searchCriteria : "");
				searchCriteria = (searchCriteria === null || searchCriteria.trim() === "" ? null : searchCriteria.replace(/"/gi, "'").replace(/\\/gi, "").trim());
				if (searchCriteria != null) {
					let tokens = searchCriteria.split(/[ \t\s]+/);
					if (tokens.length > 0) {
						for (var i = 0; i < tokens.length; i++) {
							if (tokens[i].indexOf("(") == (-1)
									&& tokens[i].indexOf(")") == (-1)
									&& tokens[i].indexOf("&") == (-1)
									&& tokens[i].indexOf("|") == (-1)
									&& tokens[i].indexOf("!") == (-1)
									&& tokens[i].indexOf(":") == (-1)
									&& tokens[i].indexOf("*") == (-1)
									&& tokens[i].indexOf("\"") == (-1)
									&& tokens[i].indexOf("'") == (-1)) {
								tokens[i] = tokens[i] + ":*";
							}
						}
					}
					if (tokens.length > 0) {
						searchCriteria = tokens[0];
						for (var i = 0; i < tokens.length - 1; i++) {
							if (tokens[i] != "(" && tokens[i] != ")" && tokens[i] != "&" && tokens[i] != "|"
									&& tokens[i + 1] != "(" && tokens[i + 1] != ")" && tokens[i + 1] != "&" && tokens[i + 1] != "|") {
								searchCriteria += " &";
							}
							searchCriteria += " " + tokens[i + 1];
						}
					}
					//console.log("Search criteria: " + searchCriteria);
				}
				keys.filter(key => key.textSearch).map(key => Object.values(key.attributes).map(attribute => attribute.name in searchAttributeNames ? null : searchAttributeNames.push(attribute.name)));
			}
			
			let filtersApplied = false;
			
			let where = [];
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				
				if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let value = (this.basicFilterRefs[attribute.name].current.select.state.value == null ? null : this.basicFilterRefs[attribute.name].current.select.state.value);
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
					} 
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
					if (value != null && value != "") {
						where.push('{' + attribute.name + ': {EQ: ' + value.value + '}}');
						filtersApplied = true;
					}
				}
				else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"]) {
						if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
							let value = this.basicFilterRefs[attribute.name].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
						if (value != null && value != "") {
							let escapedValue = (value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').trim() === "" ? null : value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').replace(/"/gi, "'").trim().split(/\s+/).join(":* &") + ":*");
							where.push('{ OR: [{' + attribute.name + ': {SEARCH: {query: "' + escapedValue + '" config: ' + this.getLanguage(entityLocalModel.language) + '}}} {' + attribute.name + ': {EQ: "' + value + '"}}]}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
				}
				else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let value = this.basicFilterRefs[attribute.name].current.value;
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
					}
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
					if (value != null && value != "" && value != "_null" && value != "_blank") {
						where.push('{' + attribute.name + ': {EQ: ' + value + '}}');
						filtersApplied = true;
					}
					else if (value == "_null") {
						where.push('{' + attribute.name + ': {IS_NULL: true}}');
						filtersApplied = true;
					}
				}
				else if (!attribute.array
						&& attribute.type === "BOOLEAN") {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
						if (this.props.context.state[this.props.entity].basicFilters[attribute.name] == false) {
							where.push('{OR: [{' + attribute.name + ': {EQ: false}} {' + attribute.name + ': {IS_NULL: true}}]}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {EQ: ' + this.props.context.state[this.props.entity].basicFilters[attribute.name] + '}}');
							filtersApplied = true;
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "INTEGER"
							|| attribute.type === "SMALLINT"
							|| attribute.type === "BIGINT"
							|| attribute.type === "SERIAL"
							|| attribute.type === "DECIMAL"
							|| attribute.type === "DOUBLE_PRECISION"
							|| attribute.type === "REAL"
							|| attribute.type === "MONEY"
							|| attribute.type === "SMALLSERIAL"
							|| attribute.type === "BIGSERIAL")) {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "From"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {GE: ' + Number(value) + '}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "To"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {LE: ' + Number(value) + '}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "From"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {GE: "' + value.replace("T", " ") + '"}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "To"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {LE: "' + value.replace("T", " ") + '"}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
				}
			});
			
			let orderBy = null;
			if (this.props.context.state[this.props.entity].orderBy !== null) {
				if (this.props.context.state[this.props.entity].orderByCustomExpression != null) {
					orderBy = ' orderBy: { customExpression: "' + this.props.context.state[this.props.entity].orderByCustomExpression + '" direction: ' + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'ASC' : 'DESC') + ' nullsGo: ' + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'LAST' : 'FIRST') + "} ";
				}
				else {
					orderBy = " orderBy: { attribute: " + this.props.context.state[this.props.entity].orderBy + " direction: " + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'ASC' : 'DESC') + " nullsGo: " + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'LAST' : 'FIRST') + "} ";
				}
			}
			else {
				let labelAttributes = Object.values(entityModel.attributes)
						.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type != "DOCUMENT" && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language) && !attribute.array);
				if (labelAttributes != null && labelAttributes.length > 0) {
					orderBy = " orderBy: [";
					labelAttributes.slice(0, 1).forEach(attribute => { orderBy += "{ attribute: " + attribute.name + " nullsGo: LAST }, "});
					orderBy += "] ";
				}
			}
			
			if (searchEnabled && searchCriteria != null) {
				where.push('{' + searchAttributeNames[0] + ': {SEARCH: {query: "' + searchCriteria + '" config: ' + this.getLanguage(entityLocalModel.language) + '}}}');
			}
			if (this.props.filterAttribute !== undefined && this.props.filterValue !== undefined) {
				where.push('{' + this.props.filterAttribute + ': {EQ: ' + this.setQuotes(entityModel.attributes[this.props.filterAttribute], this.props.filterValue) + '}}');
			}
			else {
				let filterAttribute = null;
				let filterValue = null;
				let hash = window.location.hash;
				if (hash != null && hash != "") {
					hash.split(/[#&,]/).forEach(item => { 
						//console.log("Item: " + item);
						let itemKey = item.split("=")[0];
						let itemValue = item.split("=")[1];
						if (itemKey == "filterAttribute") { 
							filterAttribute = itemValue;
						}
						else if (itemKey == "filterValue") {
							filterValue = itemValue;
						}
					});
				}
				if (filterAttribute != null && filterValue != null) {
					where.push('{' + filterAttribute + ': {EQ: ' + this.setQuotes(entityModel.attributes[filterAttribute], filterValue) + '}}');
				}
			}
			
			// Although several attributes can be added to the primary key in the API, 
			// we have simplified and assume only one in the administrative tool, because of the router, surrogate key, ...
			let primaryKeys = keys.filter(key => key.primaryKey);
			if (primaryKeys == null 
					|| primaryKeys.length == 0 
					|| primaryKeys.length > 1 
					|| primaryKeys[0].attributes == null
					|| primaryKeys[0].attributes.length == 0
					|| primaryKeys[0].attributes.length > 1) {
				
				this.setState({
					message: this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
					messageError: true,
					messageOpened: true,
					deleteConfirmationDialogOpened: false,
				}, () => this.props.context.hideActivityIndicator());
			}
			else {
				let keyAttribute = primaryKeys[0].attributes[0];
				
				if (this.props.disabled) {
					let state = {
							keyAttribute: keyAttribute,
							currentEntity: this.props.entity,
							data: [],
							attributes: attributes,
							searchEnabled: searchEnabled,
							scrollEnabled: false,
					};
					this.setState(state, () => this.props.context.hideActivityIndicator());
				}
				else {
					const query = 
							'{ ' +
							'   token: refreshToken(token: "' + this.props.context.accessToken + '")' +
							'	result:' + this.props.entity.replace(".", "_") + 'List(' +
							'		limit: 100000 ' +
							(orderBy != null ? orderBy : "") + 
							(where.length > 0 ? ' where: {AND: [' + where.join(" ") + ']}' : '') +
							'	) { ' + keyAttribute.name + ', ' +
							'   	' + attributes.map(attribute => {
									if (attribute.referenceAttributeName !== undefined && model.entities[attribute.referencedKey.entityName] != null) {
										let subPrimaryKeys = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey);
										if (subPrimaryKeys == null 
												|| subPrimaryKeys.length == 0 
												|| subPrimaryKeys.length > 1 
												|| subPrimaryKeys[0].attributes == null
												|| subPrimaryKeys[0].attributes.length == 0
												|| subPrimaryKeys[0].attributes.length > 1) {
											
											this.setState({
												message: attribute.referencedKey.entityName + ": " + this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
												messageError: true,
												messageOpened: true,
												deleteConfirmationDialogOpened: false,
											}, () => this.props.context.hideActivityIndicator());
											return "";
										}
										else {
											let subKeyAttribute = subPrimaryKeys[0].attributes[0];
											return attribute.referenceAttributeName + "{ " + subKeyAttribute.name + ", " + this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) + "}";
										}
									}
									else {
										if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
											return attribute.name + " { name thumbnail type }";
										}
										else {
											return attribute.name;
										}
									}
								}) + " " +
								
								Object.keys(entityLocalModel.attributes)
										.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty
												&& this.props.context.state[this.props.entity].basicFilters[attributeName])
										.map(attributeName => attributeName + "(joinType: INNER limit: 1) { " + Object.values(Object.values(model.entities).filter(entity => Object.values(entity.references).filter(reference => reference.referencedKey.entityName == entityModel.schema + "." + entityModel.name).length > 0).filter(entity => attributeName.startsWith(entity.name))[0].keys).filter(key => key.primaryKey)[0].attributes[0].name + " }")
										.join(" ") +
								
							'	} ' +
							'}';
					
					const variables = {
			    		authorization: this.props.context.accessToken
			    	};
					let request = JSON.stringify({query: query, variables: variables});
					
					//console.log("Query: " + query);
					//console.log(variables);
					
					fetch(this.props.context.baseUrl + "/graphql", {
						method: "POST",
						body: request
					})
					.then(response => response.json())
					.then(json => {
						if (json != null
								&& json.errors != null
								&& json.errors[0] != null
								&& json.errors[0].message == "SessionTimeout") {
							document.location = '/login';
						}
						
						// This is considered an anti-pattern
						if (this._isMounted) {
							let diagramModel = null;
							
							if (this.state.data != null) {
								this.state.data.filter(item => item._selected)
										.forEach(item => {
											json.data["result"].filter(item2 => item2[keyAttribute.name] == item[keyAttribute.name])
													.forEach(item2 => {
														item2._selected = (this.state.limit == 100 ? false : item._selected);
													});
										});
							}
							
							if (json == null || json.data == null) {
								console.log(json);
								this.setState({
									message: this.props.t('error'),
									messageError: true,
									messageOpened: true,
									deleteConfirmationDialogOpened: false,
								}, () => this.props.context.hideActivityIndicator());
							}
							else {
							
								let state = {
									keyAttribute: keyAttribute,
									currentEntity: this.props.entity,
									data: json.data["result"],
									attributes: attributes,
								};
								
								/*
								attributes.filter(attribute => 
									(attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name] != null && (entityLocalModel.attributes[attribute.name].list === undefined || entityLocalModel.attributes[attribute.name].list))
										|| (attribute.referenceAttributeName != null && entityLocalModel.attributes[attribute.referenceAttributeName] != null && (entityLocalModel.attributes[attribute.referenceAttributeName].list === undefined || entityLocalModel.attributes[attribute.referenceAttributeName].list))
								).map(attribute => {
									csvContent += this.props.t('e.' + this.props.entity + '.a.' + attribute.name) + ",";
								});
								
								csvContent += "\n";
								*/
	
								let headerDefinition = exportSettings.workSheets[0].tableSettings.table1.headerDefinition;
								attributes.filter(attribute => 
										(attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name] != null && (entityLocalModel.attributes[attribute.name].list === undefined || entityLocalModel.attributes[attribute.name].list))
										|| (attribute.referenceAttributeName != null && entityLocalModel.attributes[attribute.referenceAttributeName] != null && (entityLocalModel.attributes[attribute.referenceAttributeName].list === undefined || entityLocalModel.attributes[attribute.referenceAttributeName].list))
								).map((attribute, attributeIndex) => {
									if (headerDefinition[attributeIndex] == null) {
										headerDefinition.push({});
									}
									headerDefinition[attributeIndex] = {
										name: this.props.t('e.' + this.props.entity + '.a.' + attribute.name),
										key: attribute.name,
										customWidth: true,
										width: ((attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") && entityLocalModel.attributes[attribute.name].length != null) ? entityLocalModel.attributes[attribute.name].length : 20,
									};
								});
								
								let data = [
									{
										table1: []
									}
								];
								
								let table1 = data[0].table1;
								
								json.data["result"].map((item, itemIndex) => {
									this.state.attributes
											.filter(attribute => 
													(attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name] != null && (entityLocalModel.attributes[attribute.name].list === undefined || entityLocalModel.attributes[attribute.name].list))
													|| (attribute.referenceAttributeName != null && entityLocalModel.attributes[attribute.referenceAttributeName] != null && (entityLocalModel.attributes[attribute.referenceAttributeName].list === undefined || entityLocalModel.attributes[attribute.referenceAttributeName].list))
											)
											.map(attribute => {
	
										if (table1[itemIndex] == null) {
											table1.push({});
										}
										let itemData = table1[itemIndex];
										
										if (attribute.referenceAttributeName === undefined) {
											if (attribute.array 
													&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
												itemData[attribute.name] = (item[attribute.name] == null ? "" : item[attribute.name].join(", ").replace('"', '\"'));
											}
											else if (!attribute.array
													&& attribute.enumType != null
													&& item[attribute.name] != null) {
												itemData[attribute.name] = this.props.t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + item[attribute.name]);
											}
											else if (!attribute.array
													&& attribute.type === 'BOOLEAN') {
												itemData[attribute.name] = (item[attribute.name] ? true : false);
											}
											else if (!attribute.array
													&& attribute.type === 'DATE') {
												itemData[attribute.name] = (item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleDateString());
											}
											else if (!attribute.array
													&& attribute.type === 'TIMESTAMP') {
												itemData[attribute.name] = (item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleString());
											}
											else if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
												itemData[attribute.name] = "";
											}
											else if (!attribute.array
													&& (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL')) {
												itemData[attribute.name] = new Number(item[attribute.name]);
											}
											else {
												itemData[attribute.name] = (item[attribute.name] == null ? "" : item[attribute.name].replace('"', '\"'));
											}
										}
										else {
											if (item[attribute.referenceAttributeName] != null) {
												itemData[attribute.name] = item[attribute.referenceAttributeName] === null ? "" : this.getLabelLegacyText(item[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName]).replace('"', '\"');
											}
										}
										//csvContent += ",";
									});
									//csvContent += "\n";
								});
	
								/*
								var a = document.createElement('a');
								a.href = "data:text/csv;base64," + btoa(unescape(encodeURIComponent(csvContent)));
								a.download = this.props.entity.replace('.', '-') + ".csv";
								document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
								a.click();    
								a.remove();  //afterwards we remove the element again         
								*/
								
								/*
								console.log(exportSettings);
								console.log(data);
								*/
								
								let excelExport = new ExcelExport();
								excelExport.downloadExcel(exportSettings, data);		
								
								this.props.context.hideActivityIndicator();
							}
						}
						else {
							this.props.context.hideActivityIndicator();
						}
					})
					.catch(error => {
						console.log("!!!!! Retrying...");
						console.log(error);
						console.log(request);
						setTimeout(this.refreshDataThrottled(), 500);
					});
				}
			}
		}
		catch (error) {
			console.log(error);
			this.go("/login");
		}
	}
	
	handleDelete(event) {
		this.props.context.showActivityIndicator();
		const selectedItems = this.state.data.filter(item => item._selected);
		
		let query = 'mutation { ' + this.props.entity.replace(".", "_") + 'Delete( where: { ' + this.state.keyAttribute.name + ': { IN: [' 
				+ selectedItems.map(item => this.setQuotes(this.state.keyAttribute, item[this.state.keyAttribute.name])).toString() 
				+ '] }}) { ' + this.state.keyAttribute.name + ' }}';
		
		const variables = {
    		authorization: this.props.context.accessToken
    	};
    	
		// console.log("Query: " + query);
		
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json != null
					&& json.errors != null
					&& json.errors[0] != null
					&& json.errors[0].message == "SessionTimeout") {
				document.location = '/login';
			}
			
			// This is considered an anti-pattern
			if (this._isMounted) {
				if (json.errors != null) {
					this.setState({
						message: this.getErrorMessage(json.errors[0].message),
						messageError: true,
						messageOpened: true,
						deleteConfirmationDialogOpened: false,
					}, () => this.refreshDataThrottled());
				}
				else {
					if (this.props.entity == 'Models.Personalization') {
						refreshPersonalization(this.props.theme, this.props.context.baseUrl).then(result => {
							this.forceUpdate();
							this.setState({
								message: selectedItems.length + " " + this.props.t('deleteSuccess'),
								messageError: false,
								messageOpened: true,
								deleteConfirmationDialogOpened: false,
							}, () => this.refreshDataThrottled());
						});
					}
					else if (this.props.entity.startsWith("Models.")) {
						refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context).then(result => {
							this.forceUpdate();
							this.setState({
								message: selectedItems.length + " " + this.props.t('deleteSuccess'),
								messageError: false,
								messageOpened: true,
								deleteConfirmationDialogOpened: false,
							}, () => this.refreshDataThrottled());
						});
					}
					else {
						this.setState({
							message: selectedItems.length + " " + this.props.t('deleteSuccess'),
							messageError: false,
							messageOpened: true,
							deleteConfirmationDialogOpened: false,
						}, () => this.refreshDataThrottled());
					}
				}
			}
			else {
				this.props.context.hideActivityIndicator();
			}
		});
	}
	
	handleSortClick(event, attribute, model) {
		if (attribute.referenceAttributeName != null) {
			let entityModel = model.entities[attribute.referencedKey.entityName];
			let entityLocalModel = localModel.entities[attribute.referencedKey.entityName];
			let labelAttributeName = Object.values(entityModel.attributes).filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))[0].name;
			this.props.context.state[this.props.entity].orderByCustomExpression = '\\\"' + attribute.referenceAttributeName + '_2\\\".\\\"' + labelAttributeName + '\\\"';
		}
		else {
			this.props.context.state[this.props.entity].orderByCustomExpression = null;
		}
		
		if (this.props.context.state[this.props.entity].orderBy !== attribute.name) {
			this.props.context.state[this.props.entity].orderBy = attribute.name;
			this.props.context.state[this.props.entity].orderDirection = "asc";
			this.setState({
				// No se utiliza, pero sirve para forzar el refresco de la pantalla
				orderBy: attribute.name,
				orderDirection: 'asc',
			}, () => this.refreshDataThrottled());
		}
		else {
			this.props.context.state[this.props.entity].orderBy = attribute.name;
			this.props.context.state[this.props.entity].orderDirection = (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'desc' : 'asc');
			this.setState((state, props) => ({
				orderBy: attribute.name,
				orderDirection: (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'desc' : 'asc'),
			}), () => this.refreshDataThrottled());
		}
	}
	
	handleDiagramEvent(event) {
		// Mostrar / Ocultar barra de edición
		if (event.function == "selectionChanged" && event.entity instanceof DefaultNodeModel) {
			let entityName = event.entity.options.name;
			let schema = entityName.split(".")[0];
			let name = entityName.split(".")[1];
			let items = this.state.data.filter(item => item.schema == schema && item.name == name);
			if (items != null && items[0] != null) {
				items[0]._selected = event.isSelected;
				this.setState((state, props) => ({
					data: this.state.data,
				}));
			}
		}
		
		// Crear referencia
		else if (event.function == "linksUpdated" && event.isCreated) {
			let link = event.link;
			link.registerListener({
				eventDidFire: this.handleDiagramEvent
			});
		}
		
		// Terminar de enlazar la referencia creada
		else if (event.function == "targetPortChanged") {
			this.props.context.showActivityIndicator();
			
			let sourceEntity = event.entity.sourcePort.parent.options.name;
			let targetEntity = event.entity.targetPort.parent.options.name;
			
			let sourceEntitySchema = sourceEntity.split(".")[0];
			let sourceEntityName = sourceEntity.split(".")[1];
			let targetEntitySchema = targetEntity.split(".")[0];
			let targetEntityName = targetEntity.split(".")[1];
			let referenceName = event.entity.sourcePort.options.name.split(" ")[1];
			
			let query = "{container: Models_EntityList(where: {AND: [{schema: {EQ: \"" + sourceEntitySchema + "\"}} {name: {EQ: \"" + sourceEntityName + "\"}}]}) {id} referencedKey: Models_EntityKeyList(where: {isPrimaryKey: {EQ: true}}) {id container: EntityViaContainer(joinType: INNER where: {AND: [{schema: {EQ: \"" + targetEntitySchema + "\"}} {name: {EQ: \"" + targetEntityName + "\"}}]}) {id}}}";
			const variables = {
	    		authorization: this.props.context.accessToken
	    	};
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.baseUrl + "/graphql", {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (json != null
						&& json.errors != null
						&& json.errors[0] != null
						&& json.errors[0].message == "SessionTimeout") {
					document.location = '/login';
				}
				
				let containerId = json.data.container[0].id;
				let referencedKeyId = json.data.referencedKey[0].id;
				
				query = "mutation {Models_EntityReferenceCreate(entity: {container: " + containerId + " referencedKey: " + referencedKeyId + " name: \"" + referenceName + "\" isBasicFilter: true isCascadeDelete: true isList: true isVisible: true listIsVisible: true}) {id}}";
				let request = JSON.stringify({query: query, variables: variables});
				fetch(this.props.context.baseUrl + "/graphql", {
					method: "POST",
					body: request
				})
				.then(response => response.json())
				.then(json => {
					if (json != null
							&& json.errors != null
							&& json.errors[0] != null
							&& json.errors[0].message == "SessionTimeout") {
						document.location = '/login';
					}
					
					refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context).then(result => {
						this.setState({
							message: this.props.t('referenceCreated'),
							messageError: false,
							messageOpened: true,
						}, () => setTimeout(() => this.refreshDataThrottled(), 500));
					});
				});
			});
		}
		
		// Borrar entidad
		else if (event.function == "entityRemoved" && event.entity instanceof DefaultNodeModel) {
			this.setState({
				message: this.props.t('deleteNotImplemented'),
				messageError: false,
				messageOpened: true,
			}, () => setTimeout(() => this.refreshDataThrottled(), 500));
			//console.log("Deleting entity. Not-implemented.");
		}
		
		// Borrar referencia
		else if (event.function == "entityRemoved" && event.entity instanceof DefaultLinkModel) {
			this.setState({
				message: this.props.t('deleteNotImplemented'),
				messageError: false,
				messageOpened: true,
			}, () => setTimeout(() => this.refreshDataThrottled(), 500));
			//console.log("Deleting link. Not-implemented.");
			/*
			this.props.context.showActivityIndicator();
			
			let sourceEntity = event.entity.sourcePort.parent.options.name;
			
			let sourceEntitySchema = sourceEntity.split(".")[0];
			let sourceEntityName = sourceEntity.split(".")[1];
			let referenceName = event.entity.sourcePort.options.name.split(" ")[1];
			
			let query = "{container: Models_EntityList(where: {AND: [{schema: {EQ: \"" + sourceEntitySchema + "\"}} {name: {EQ: \"" + sourceEntityName + "\"}}]}) {id}}";
			const variables = {
	    		authorization: this.props.context.accessToken
	    	};
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.baseUrl + "/graphql", {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (json != null
						&& json.errors != null
						&& json.errors[0] != null
						&& json.errors[0].message == "SessionTimeout") {
					document.location = '/login';
				}
				
				let containerId = json.data.container[0].id;
				
				query = "mutation {Models_EntityReferenceDelete(where: {AND: [{container: {EQ: " + containerId + "}} {name: {EQ: \"" + referenceName + "\"}}]}) {id}}";
				let request = JSON.stringify({query: query, variables: variables});
				fetch(this.props.context.baseUrl + "/graphql", {
					method: "POST",
					body: request
				})
				.then(response => response.json())
				.then(json => {
					if (json != null
							&& json.errors != null
							&& json.errors[0] != null
							&& json.errors[0].message == "SessionTimeout") {
						document.location = '/login';
					}
					
					refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context).then(result => {
						this.setState({
							message: this.props.t('referenceDeleted'),
							messageError: false,
							messageOpened: true,
						}, () => setTimeout(() => this.refreshDataThrottled(), 500));
					});
				});
			});
			*/
		}
	}
	
	// Life cycle methods
	
	componentDidMount() {
		// console.log(">> EntityList.componentDidMount");
		this.props.context.handleDrawerClose();
		this._isMounted = true;
		this.refreshDataThrottled();
	}
	
	componentDidUpdate(prevProps) {
		// console.log(">> EntityList.componentDidUpdate");
		if (this.props.entity !== prevProps.entity
				|| this.props.filterAttribute !== prevProps.filterAttribute
				|| this.props.filterValue !== prevProps.filterValue
				|| this.props.refresh !== prevProps.refresh) {
			this.props.context.handleDrawerClose();
			window.scrollTo(0, 0);
			let entityLocalModel = localModel.entities[this.props.entity];
			let localAttributes = Object.values(entityLocalModel.attributes);
			
			if (this.props.context.state[this.props.entity] == null) {
				this.props.context.state[this.props.entity] = {};
			}
			if (this.props.context.state[this.props.entity].mode == null) {
				this.props.context.state[this.props.entity].mode = (localAttributes.filter(attribute => attribute.imageInGallery).length > 0 ? "gallery" : (localAttributes.filter(attribute => attribute.pointInMap).length > 0 ? "table" : "table"));
			}
			if (this.props.context.state[this.props.entity].basicFiltersEnabled == null) {
				this.props.context.state[this.props.entity].basicFiltersEnabled = false;
			}
			if (this.props.context.state[this.props.entity].intelligenceEnabled == null) {
				this.props.context.state[this.props.entity].intelligenceEnabled = false;
			}
			if (this.props.context.state[this.props.entity].basicFilters == null) {
				this.props.context.state[this.props.entity].basicFilters = {};
			}
			if (this.props.context.state[this.props.entity].orderBy == null) {
				this.props.context.state[this.props.entity].orderBy = null;
			}
			if (this.props.context.state[this.props.entity].orderDirection == null) {
				this.props.context.state[this.props.entity].orderDirection = "asc";
			}
			Object.keys(entityLocalModel.attributes)
					.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
					.forEach(attributeName => {
						if (this.props.context.state[this.props.entity].basicFilters[attributeName] == null) {
							this.props.context.state[this.props.entity].basicFilters[attributeName] = true;
						}
					});
			this.setState({
				limit: 100,
			}, () => this.refreshDataThrottled());
		}
	}
	
	componentWillUnmount() {
		//console.log(">> EntityList.componentWillUnmount");
		this._isMounted = false;
	}
	
	// Other methods
	
	setQuotes(attribute, value) {
		const hasQuotes = (
			((attribute.type === "TEXT"
				|| attribute.type === "VARCHAR"
				|| attribute.type === "CHAR"
			) && attribute.enumType == null)
			|| attribute.type === "DATE"
			|| attribute.type === "TIMESTAMP"
			|| attribute.type === "TIME"
			|| attribute.type === "INTERVAL"
			|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
			|| attribute.type === "TIME_WITH_TIME_ZONE"
			|| attribute.type === "POINT"
			|| attribute.type === "POLYGON"
		);
		return (hasQuotes ? '"' : '') + value + (hasQuotes ? '"' : '');
	}
	
	getLabelAttributesQueryString(entityModel, entityLocalModel, exceptionAttribute, depth) {
		let str = "";
		
		if (depth == null) {
			depth = 1;
		}
		
		Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.forEach(attribute => {
				if (exceptionAttribute == null || attribute.name != exceptionAttribute.name) {
					if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
						str += attribute.name + " { name thumbnail type }, ";
					}
					else {
						str += attribute.name + ", ";
					}
				}
			});
		
		if (depth < 5) {
			Object.values(entityModel.references)
				.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
				.forEach(reference => {
					const model = this.props.context.model;
					
					let subLabel = this.getLabelAttributesQueryString(model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName], null, depth + 1);
					
					if (subLabel === "") {
						subLabel = Object.values(model.entities[reference.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0].name + ", ";
					}
					
					str += reference.referenceAttributeName + "{ " 
							+ subLabel
							+ "}, ";
				});
		}
		
		return str;
	}
	
	getLabel(item, entityModel, entityLocalModel) {
		if (item == null) {
			return null;
		}
		
		const model = this.props.context.model;
		
		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: (item[attribute.name] == null ? "" : (attribute.type == "CUSTOM_TYPE" && entityLocalModel.attributes[attribute.name].type == "DOCUMENT" && item[attribute.name] != null ? <img style={{verticalAlign: "middle", maxHeight: "48px", maxWidth: "96px", paddingRight: 10}} src={(item[attribute.name] == null ? "" : item[attribute.name].thumbnail)}></img> : <span style={{paddingRight: 10}}>{(entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL' ? (entityLocalModel.attributes[attribute.name].step == null ? new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator }) : new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator, minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)})) : item[attribute.name]) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix)}</span>)),
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return <>{labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label)}</>;
	}
	
	getLabelLegacyText(item, entityModel, entityLocalModel) {
		if (item == null || entityModel == null || entityLocalModel == null) {
			return null;
		}
		
		const model = this.props.context.model;
		
		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: (item[attribute.name] == null ? "" : (attribute.type == "CUSTOM_TYPE" && entityLocalModel.attributes[attribute.name].type == "DOCUMENT" && item[attribute.name] != null ? item[attribute.name].name : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL' ? (entityLocalModel.attributes[attribute.name].step == null ? new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator }) : new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator, minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)})) : item[attribute.name]) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix))),
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label).join(", ");
	}
	
	getLanguage(lang) {
		let language = "ENGLISH";
		switch (lang) {
			case 'da':
				return 'DANISH';
			case 'nl': 
				return 'DUTCH';
			case 'en_GB': 
				return 'ENGLISH';
			case 'en_US': 
				return 'ENGLISH';
			case 'fi': 
				return 'FINNISH';
			case 'fr_CA': 
				return 'FRENCH';
			case 'fr_FR': 
				return 'FRENCH';
			case 'de': 
				return 'GERMAN';
			case 'it': 
				return 'ITALIAN';
			case 'no': 
				return 'NORWEGIAN';
			case 'pt_BR': 
				return 'PORTUGUESE';
			case 'pt_PT': 
				return 'PORTUGUESE';
			case 'ro': 
				return 'ROMANIAN';
			case 'ru': 
				return 'RUSSIAN';
			case 'es_419': 
				return 'SPANISH';
			case 'es_ES': 
				return 'SPANISH';
			case 'sv': 
				return 'SWEDISH';
			case 'tr': 
				return 'TURKISH';
			default: 
				return 'ENGLISH';
		}
	}
	
	refreshData() {
		try {
			// console.log(">> EntityList.refreshData")
			this.props.context.showActivityIndicator();
			
			const model = this.props.context.model;
			
			const entityModel = model.entities[this.props.entity];
			const entityLocalModel = localModel.entities[this.props.entity];

			this.setState({
				predictions: null
			});
			
			//console.log("entityModel:");
			//console.log(entityModel);
			//console.log("entityLocalModel:");
			//console.log(entityLocalModel);
			
			let attributes = Object.values(entityModel.attributes)
					.filter(attribute => (attribute.type !== "CUSTOM_TYPE" || entityLocalModel.attributes[attribute.name].type == "DOCUMENT") 
							&& entityLocalModel.attributes 
							&& entityLocalModel.attributes[attribute.name] 
							&& (
									entityLocalModel.attributes[attribute.name].list === undefined 
									|| entityLocalModel.attributes[attribute.name].list
									|| entityLocalModel.attributes[attribute.name].pointInMap
									|| entityLocalModel.attributes[attribute.name].imageInGallery
									|| entityLocalModel.attributes[attribute.name].basicFilter
									|| entityLocalModel.attributes[attribute.name].highlighter
							)
					);
			
			Object.values(entityModel.references)
					.filter(reference => { 
						//console.log("reference:");
						//console.log(reference);
						return entityModel.attributes[reference.name]
								&& (entityLocalModel.attributes[reference.referenceAttributeName] === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].list === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].list
								|| entityLocalModel.attributes[reference.referenceAttributeName].basicFilter);
					})
					.forEach(reference => {
						attributes.push({
							name: reference.name,
							entityName: reference.entityName,
							referenceAttributeName: reference.referenceAttributeName,
							attributes: reference.attributes,
							referencedKey: reference.referencedKey,
							type: entityModel.attributes[reference.name].type,
						});
					});
			
			attributes = attributes.filter(attribute => this.props.filterAttribute === undefined || attribute.name !== this.props.filterAttribute);
			
			attributes.sort((a, b) => 
				(	
					entityLocalModel.attributes[a.referenceAttributeName || a.name] == null 
						|| (entityLocalModel.attributes[a.referenceAttributeName || a.name].order == null && entityLocalModel.attributes[a.referenceAttributeName || a.name].orderInList == null) 
							? Infinity 
							: (entityLocalModel.attributes[a.referenceAttributeName || a.name].orderInList == null ? entityLocalModel.attributes[a.referenceAttributeName || a.name].order : entityLocalModel.attributes[a.referenceAttributeName || a.name].orderInList)
				) - (
					entityLocalModel.attributes[b.referenceAttributeName || b.name] == null 
						|| (entityLocalModel.attributes[b.referenceAttributeName || b.name].order == null && entityLocalModel.attributes[b.referenceAttributeName || b.name].orderInList == null) 
							? Infinity 
							: (entityLocalModel.attributes[b.referenceAttributeName || b.name].orderInList == null ? entityLocalModel.attributes[b.referenceAttributeName || b.name].order : entityLocalModel.attributes[b.referenceAttributeName || b.name].orderInList)
				)
			);
			
			let keys = Object.values(entityModel.keys);
			const searchEnabled = keys.filter(key => key.textSearch).length > 0;
			let searchCriteria = null;
			let searchAttributeNames = [];
			
			if (searchEnabled) {
				if (this.props.context.state == null) {
					this.props.context.state = {};
				}
				if (this.props.context.state[this.props.entity] == null) {
					this.props.context.state[this.props.entity] = {};
				}
				if (this.searchInput.current != null && this.searchInput.current.value != null) {
					this.props.context.state[this.props.entity].searchCriteria = this.searchInput.current.value;
				}
				if (this.props.context.state[this.props.entity].searchCriteria == null) {
					this.props.context.state[this.props.entity].searchCriteria = "";
				}
				searchCriteria = (this.props.context.state[this.props.entity].searchCriteria != null ? this.props.context.state[this.props.entity].searchCriteria : "");
				searchCriteria = (searchCriteria === null || searchCriteria.trim() === "" ? null : searchCriteria.replace(/"/gi, "'").replace(/\\/gi, "").trim());
				if (searchCriteria != null) {
					let tokens = searchCriteria.split(/[ \t\s]+/);
					if (tokens.length > 0) {
						for (var i = 0; i < tokens.length; i++) {
							if (tokens[i].indexOf("(") == (-1)
									&& tokens[i].indexOf(")") == (-1)
									&& tokens[i].indexOf("&") == (-1)
									&& tokens[i].indexOf("|") == (-1)
									&& tokens[i].indexOf("!") == (-1)
									&& tokens[i].indexOf(":") == (-1)
									&& tokens[i].indexOf("*") == (-1)
									&& tokens[i].indexOf("\"") == (-1)
									&& tokens[i].indexOf("'") == (-1)) {
								tokens[i] = tokens[i] + ":*";
							}
						}
					}
					if (tokens.length > 0) {
						searchCriteria = tokens[0];
						for (var i = 0; i < tokens.length - 1; i++) {
							if (tokens[i] != "(" && tokens[i] != ")" && tokens[i] != "&" && tokens[i] != "|"
									&& tokens[i + 1] != "(" && tokens[i + 1] != ")" && tokens[i + 1] != "&" && tokens[i + 1] != "|") {
								searchCriteria += " &";
							}
							searchCriteria += " " + tokens[i + 1];
						}
					}
					//console.log("Search criteria: " + searchCriteria);
				}
				keys.filter(key => key.textSearch).map(key => Object.values(key.attributes).map(attribute => attribute.name in searchAttributeNames ? null : searchAttributeNames.push(attribute.name)));
			}
			
			//console.log("Attributes: ");
			//console.log(attributes);

			let filtersApplied = false;
			
			if (this.basicFilterRefs == null) {
				this.basicFilterRefs = {};
			}
			
			//console.log(entityLocalModel);
			//console.log(attributes);
			
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				if (attribute.incomingReferenceAttributeName === undefined)	{
					if (!attribute.array
							&& attribute.referenceAttributeName !== undefined) {
						if (this.basicFilterRefs[attribute.name] == null) {
							this.basicFilterRefs[attribute.name] = React.createRef();
						}
					}
					else if (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") {
						if (this.basicFilterRefs[attribute.name] == null) {
							this.basicFilterRefs[attribute.name] = React.createRef();
						}
					}
					else if (!attribute.array
							&& (attribute.type === "INTEGER"
								|| attribute.type === "SMALLINT"
								|| attribute.type === "BIGINT"
								|| attribute.type === "SERIAL"
								|| attribute.type === "DECIMAL"
								|| attribute.type === "DOUBLE_PRECISION"
								|| attribute.type === "REAL"
								|| attribute.type === "MONEY"
								|| attribute.type === "SMALLSERIAL"
								|| attribute.type === "BIGSERIAL"
								
								|| attribute.type === "DATE")
								|| attribute.type === "TIMESTAMP"
								|| attribute.type === "TIME") {
						if (this.basicFilterRefs[attribute.name + "From"] == null) {
							this.basicFilterRefs[attribute.name + "From"] = React.createRef();
							this.basicFilterRefs[attribute.name + "To"] = React.createRef();
						}
					}
				}	
			});
			
			let where = [];
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				
				if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let value = (this.basicFilterRefs[attribute.name].current.select.state.value == null ? null : this.basicFilterRefs[attribute.name].current.select.state.value);
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
					} 
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
					if (value != null && value != "") {
						where.push('{' + attribute.name + ': {EQ: ' + value.value + '}}');
						filtersApplied = true;
					}
				}
				else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"]) {
						if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
							let value = this.basicFilterRefs[attribute.name].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
						if (value != null && value != "") {
							let escapedValue = (value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').trim() === "" ? null : value.replace(/[^Á-ÚA-Z0-9ñÑ\s"']/gi, '').replace(/"/gi, "'").trim().split(/\s+/).join(":* &") + ":*");
							where.push('{ OR: [{' + attribute.name + ': {SEARCH: {query: "' + escapedValue + '" config: ' + this.getLanguage(entityLocalModel.language) + '}}} {' + attribute.name + ': {EQ: "' + value + '"}}]}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
				}
				else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						let value = this.basicFilterRefs[attribute.name].current.value;
						this.props.context.state[this.props.entity].basicFilters[attribute.name] = value;
					}
					let value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
					if (value != null && value != "" && value != "_null" && value != "_blank") {
						where.push('{' + attribute.name + ': {EQ: ' + value + '}}');
						filtersApplied = true;
					}
					else if (value == "_null") {
						where.push('{' + attribute.name + ': {IS_NULL: true}}');
						filtersApplied = true;
					}
				}
				else if (!attribute.array
						&& attribute.type === "BOOLEAN") {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
						if (this.props.context.state[this.props.entity].basicFilters[attribute.name] == false) {
							where.push('{OR: [{' + attribute.name + ': {EQ: false}} {' + attribute.name + ': {IS_NULL: true}}]}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {EQ: ' + this.props.context.state[this.props.entity].basicFilters[attribute.name] + '}}');
							filtersApplied = true;
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "INTEGER"
							|| attribute.type === "SMALLINT"
							|| attribute.type === "BIGINT"
							|| attribute.type === "SERIAL"
							|| attribute.type === "DECIMAL"
							|| attribute.type === "DOUBLE_PRECISION"
							|| attribute.type === "REAL"
							|| attribute.type === "MONEY"
							|| attribute.type === "SMALLSERIAL"
							|| attribute.type === "BIGSERIAL")) {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "From"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {GE: ' + Number(value) + '}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "To"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {LE: ' + Number(value) + '}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "From"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {GE: "' + value.replace("T", " ") + '"}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
					if (this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]) {
						if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
							let value = this.basicFilterRefs[attribute.name + "To"].current.value;
							this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] = value;
						}
						let value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						if (value != null && value != "") {
							where.push('{' + attribute.name + ': {LE: "' + value.replace("T", " ") + '"}}');
							filtersApplied = true;
						}
						else {
							where.push('{' + attribute.name + ': {IS_NULL: true}}');
							filtersApplied = true;
						}
					}
				}
			});
			
			let orderBy = null;
			if (this.props.context.state[this.props.entity].orderBy !== null) {
				if (this.props.context.state[this.props.entity].orderByCustomExpression != null) {
					orderBy = ' orderBy: { customExpression: "' + this.props.context.state[this.props.entity].orderByCustomExpression + '" direction: ' + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'ASC' : 'DESC') + ' nullsGo: ' + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'LAST' : 'FIRST') + "} ";
				}
				else {
					orderBy = " orderBy: { attribute: " + this.props.context.state[this.props.entity].orderBy + " direction: " + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'ASC' : 'DESC') + " nullsGo: " + (this.props.context.state[this.props.entity].orderDirection === 'asc' ? 'LAST' : 'FIRST') + "} ";
				}
			}
			else {
				if (entityLocalModel.defaultOrderAttribute != null) {
					orderBy = " orderBy: { attribute: " + entityLocalModel.defaultOrderAttribute.name + " direction: " + (entityLocalModel.defaultOrderDesc ? 'DESC' : 'ASC') + " nullsGo: " + (entityLocalModel.defaultOrderNullsLast ? 'LAST' : 'FIRST') + "} ";
				}
				else {
					let labelAttributes = Object.values(entityModel.attributes)
							.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type != "DOCUMENT" && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language) && !attribute.array);
					if (labelAttributes != null && labelAttributes.length > 0) {
						orderBy = " orderBy: [";
						labelAttributes.slice(0, 1).forEach(attribute => { orderBy += "{ attribute: " + attribute.name + " nullsGo: LAST }, "});
						orderBy += "] ";
					}
				}
			}
			
			if (searchEnabled && searchCriteria != null) {
				where.push('{' + searchAttributeNames[0] + ': {SEARCH: {query: "' + searchCriteria + '" config: ' + this.getLanguage(entityLocalModel.language) + '}}}');
			}
			if (this.props.filterAttribute !== undefined && this.props.filterValue !== undefined) {
				where.push('{' + this.props.filterAttribute + ': {EQ: ' + this.setQuotes(entityModel.attributes[this.props.filterAttribute], this.props.filterValue) + '}}');
			}
			else {
				let filterAttribute = null;
				let filterValue = null;
				let hash = window.location.hash;
				if (hash != null && hash != "") {
					hash.split(/[#&,]/).forEach(item => { 
						//console.log("Item: " + item);
						let itemKey = item.split("=")[0];
						let itemValue = item.split("=")[1];
						if (itemKey == "filterAttribute") { 
							filterAttribute = itemValue;
						}
						else if (itemKey == "filterValue") {
							filterValue = itemValue;
						}
					});
				}
				if (filterAttribute != null && filterValue != null) {
					where.push('{' + filterAttribute + ': {EQ: ' + this.setQuotes(entityModel.attributes[filterAttribute], filterValue) + '}}');
				}
			}
			
			// Although several attributes can be added to the primary key in the API, 
			// we have simplified and assume only one in the administrative tool, because of the router, surrogate key, ...
			let primaryKeys = keys.filter(key => key.primaryKey);
			if (primaryKeys == null 
					|| primaryKeys.length == 0 
					|| primaryKeys.length > 1 
					|| primaryKeys[0].attributes == null
					|| primaryKeys[0].attributes.length == 0
					|| primaryKeys[0].attributes.length > 1) {
				
				this.setState({
					message: this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
					messageError: true,
					messageOpened: true,
					deleteConfirmationDialogOpened: false,
				}, () => this.props.context.hideActivityIndicator());
			}
			else {
				let keyAttribute = primaryKeys[0].attributes[0];
				
				//console.log(entityLocalModel);
				//console.log(entityModel);
				
				if (this.props.disabled) {
					let state = {
							keyAttribute: keyAttribute,
							currentEntity: this.props.entity,
							data: [],
							attributes: attributes,
							searchEnabled: searchEnabled,
							scrollEnabled: false,
					};
					this.setState(state, () => this.props.context.hideActivityIndicator());
				}
				else {
					const query = 
							'{ ' +
							'   token: refreshToken(token: "' + this.props.context.accessToken + '")' +
							'	result:' + this.props.entity.replace(".", "_") + 'List(' +
							'		limit: ' + this.state.limit +
							(orderBy != null ? orderBy : "") + 
							(where.length > 0 ? ' where: {AND: [' + where.join(" ") + ']}' : '') +
							'	) { ' + keyAttribute.name + ', ' +
							'   	' + attributes.map(attribute => {
									if (attribute.referenceAttributeName !== undefined && model.entities[attribute.referencedKey.entityName] != null) {
										let subPrimaryKeys = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey);
										if (subPrimaryKeys == null 
												|| subPrimaryKeys.length == 0 
												|| subPrimaryKeys.length > 1 
												|| subPrimaryKeys[0].attributes == null
												|| subPrimaryKeys[0].attributes.length == 0
												|| subPrimaryKeys[0].attributes.length > 1) {
											
											this.setState({
												message: attribute.referencedKey.entityName + ": " + this.props.t('primaryKeyWithOnlyOneAttributeNeeded'),
												messageError: true,
												messageOpened: true,
												deleteConfirmationDialogOpened: false,
											}, () => this.props.context.hideActivityIndicator());
											return "";
										}
										else {
											let subKeyAttribute = subPrimaryKeys[0].attributes[0];
											return attribute.referenceAttributeName + "{ " + subKeyAttribute.name + ", " + this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) + "}";
										}
									}
									else {
										if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
											return attribute.name + " { name thumbnail type }";
										}
										else {
											return attribute.name;
										}
									}
								}) + " " +
								
								Object.keys(entityLocalModel.attributes)
										.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty
												&& this.props.context.state[this.props.entity].basicFilters[attributeName])
										.map(attributeName => attributeName + "(joinType: INNER limit: 1) { " + Object.values(Object.values(model.entities).filter(entity => Object.values(entity.references).filter(reference => reference.referencedKey.entityName == entityModel.schema + "." + entityModel.name).length > 0).filter(entity => attributeName.startsWith(entity.name))[0].keys).filter(key => key.primaryKey)[0].attributes[0].name + " }")
										.join(" ") +
								
							'	} ' +
							'}';
					
					const variables = {
			    		authorization: this.props.context.accessToken
			    	};
					let request = JSON.stringify({query: query, variables: variables});
					
					//console.log("Query: " + query);
					//console.log(variables);
					
					fetch(this.props.context.baseUrl + "/graphql", {
						method: "POST",
						body: request
					})
					.then(response => response.json())
					.then(json => {
						if (json != null
								&& json.errors != null
								&& json.errors[0] != null
								&& json.errors[0].message == "SessionTimeout") {
							document.location = '/login';
						}
						
						// This is considered an anti-pattern
						if (this._isMounted) {
							let diagramModel = null;
							
							if (this.state.data != null) {
								this.state.data.filter(item => item._selected)
										.forEach(item => {
											json.data["result"].filter(item2 => item2[keyAttribute.name] == item[keyAttribute.name])
													.forEach(item2 => {
														item2._selected = (this.state.limit == 100 ? false : item._selected);
													});
										});
							}
							
							// Load diagram
							if (this.props.entity == "Models.Entity") {
								
								let hasApplicationData = localModel.applications != null && Object.keys(localModel.applications).length > 0;
								
								diagramModel = new DiagramModel();
								
								json.data["result"].forEach(entity => {
									
									idByName[entity.schema + "." + entity.name] = entity.id;
									
									const node = new DefaultNodeModel({
										name: entity.schema + "." + entity.name,
										color: (hasApplicationData ? (localModel.applications[entity.schema] != null && localModel.applications[entity.schema].color != null ? localModel.applications[entity.schema].color : this.props.theme.palette.secondary.main) : this.props.theme.palette.secondary.main),
									});
									node.setPosition(100, 100);
									
									let inPort = node.addPort(new AdvancedPortModel(true, "SERIAL id", "SERIAL id"));
									inPort.setLocked(true);
									
									Object.values(model.entities[entity.schema + "." + entity.name] != null && model.entities[entity.schema + "." + entity.name].attributes)
											.filter(attribute => attribute.name != "id")
											.forEach(attribute => {
										let outPort = node.addPort(new AdvancedPortModel(false, attribute.type + " " + attribute.name, attribute.type + " " + attribute.name));
										if (attribute.type != "INTEGER") {
											outPort.setLocked(true);
										}
									});
									
									node.updateDimensions({height: 26 + node.portsOut.length * 16, width: 400});
									
									node.registerListener({
										entityRemoved: event => {
											//event.stopPropagation();
											//console.log("Delete not implemented");
										},
										eventDidFire: this.handleDiagramEvent,
									});
									
									diagramModel.addAll(node);
								});
								
								json.data["result"].forEach(entity => {
									Object.values(model.entities[entity.schema + "." + entity.name] != null && model.entities[entity.schema + "." + entity.name].references).forEach(reference => {
										let sourceNode = Object.values(diagramModel.getActiveNodeLayer().getNodes()).filter(node => node.options.name == entity.schema + "." + entity.name)[0];
										let targetNode = Object.values(diagramModel.getActiveNodeLayer().getNodes()).filter(node => node.options.name == reference.referencedKey.entityName)[0];
										
										if (sourceNode != null && targetNode != null) {
											let outPort = sourceNode.portsOut.filter(port => port.options.label.split(" ")[1] == reference.name)[0];
											let inPort = targetNode.portsIn[0];
											//console.log(sourceNode);
											//console.log(targetNode);
											//console.log(outPort);
											//console.log(inPort);
											
											if (outPort != null) {
												let link = outPort.createLinkModel();
												link.setSourcePort(outPort);
												link.setTargetPort(inPort);
												link.setColor(this.props.theme.palette.secondary.main);
												
												link.registerListener({
													entityRemoved: event => {
														//event.stopPropagation();
														//console.log("Delete not implemented");
													},
													eventDidFire: this.handleDiagramEvent
												});
												
												outPort.setLocked(true);
												diagramModel.addAll(link);
											}
										}
									});
								});
								
								this.dagreEngine.redistribute(diagramModel);
								
								diagramModel.registerListener({
									eventDidFire: this.handleDiagramEvent
								});
							}
							
							let scrollEnabled = true;
							if (json.data["result"] != null && json.data["result"].length < this.state.limit) {
								scrollEnabled = false;
							}
							
							let state = {
								keyAttribute: keyAttribute,
								currentEntity: this.props.entity,
								data: json.data["result"],
								filtersApplied: filtersApplied,
								attributes: attributes,
								searchEnabled: searchEnabled,
								scrollEnabled: scrollEnabled,
								diagramModel: diagramModel,
							};
							
							Object.values(entityLocalModel.attributes)
									.filter(attribute => attribute.pointInMap)
									.forEach(attribute => {
										if (json.data["result"] != null) {
											let result = json.data["result"].filter(item => item[attribute.name] != null);
											if (result.length > 0) {
												let location = result[0][attribute.name];
												if (location != null && location != "") {
													location = location.substr(1, location.length - 2);
													state.latitude = Number(location.split(",")[0]);
													state.longitude = Number(location.split(",")[1]);
												}
											}
											else {
												state.latitude = 40.529179;
												state.longitude = -3.651276;
											}
										}
										else {
											state.latitude = 40.529179;
											state.longitude = -3.651276;
										}
									});
							
							state.questions = undefined;
							this.setState(state, () => this.props.context.hideActivityIndicator());
							
							this.props.context.setState({
								accessToken: json.data.token,
							});

							if (entityLocalModel.questions != null) {
								Object.values(entityLocalModel.questions).filter(question => question.list).forEach(question => {
									
									const questionQuery = '{QuestionURL(id: ' + question.id + (question.useIds ? ' idsParameter: [' + json.data["result"].map(item => item.id).join(",") + ']': '') + ')}';
									
									const questionQueryVariables = {
							    		authorization: this.props.context.accessToken
							    	};
									let request = JSON.stringify({query: questionQuery, variables: questionQueryVariables});
									
									fetch(this.props.context.baseUrl + "/graphql", {
										method: "POST",
										body: request
									})
									.then(response => response.json())
									.then(json => {
										if (json.errors != null && json.errors.length > 0) {
											this.setState({
												message: this.getErrorMessage(json.errors[0].message),
												messageError: true,
												messageOpened: true,
											});
										}
										else {
											this.setState((state, props) => {
												let questions = state.questions;
												if (questions == null) {
													questions = {};
												}
												questions[question.name] = {
													url: json.data.QuestionURL
												};
												return {
													questions: questions,
												};
											});
										}
									});
								});
							}
							
							if (this.props.context.state[this.props.entity].intelligenceEnabled
									&& entityLocalModel.intelligenceModels != null) {
								Object.values(entityLocalModel.intelligenceModels).forEach(intelligenceModel => {
									
									const query2 = 
										'{ ' +
										'	result:' + this.props.entity.replace(".", "_") + 'List(' +
										(orderBy != null ? orderBy : "") + 
										'		where: { id: { IN: [ ' + json.data["result"].map(item => item.id).toString() + ' ] } } ' + 
										'	) { ' + keyAttribute.name + ", " +
										intelligenceModel.features.map(feature => feature.attribute.name + ", ") + 
										'	} ' +
										'}';
									
									const variables2 = {
							    		authorization: this.props.context.accessToken
							    	};
									let request2 = JSON.stringify({query: query2, variables: variables2});
									
									fetch(this.props.context.baseUrl + "/graphql", {
										method: "POST",
										body: request2
									})
									.then(response => response.json())
									.then(json2 => {
										if (json2 != null
												&& json2.errors != null
												&& json2.errors[0] != null
												&& json2.errors[0].message == "SessionTimeout") {
											document.location = '/login';
										}
										
										let x = [];
										json2.data["result"].forEach(item => { 
											let itemArray = [];
											intelligenceModel.features.forEach(feature => {
												let featureType = feature.attribute.type;
												if (featureType == "TEXT" || featureType == "BARCODE" || featureType == "BOOLEAN" || featureType == "INTEGER" || featureType == "SERIAL" || featureType == "DECIMAL") {
													itemArray.push(item[feature.attribute.name]);
												}
												else if (featureType == "DATE") {
													let value = item[feature.attribute.name];
													if (value != null && value != "") {
														let date = new Date(value);
														itemArray.push(date.getTime() / 1000);
														itemArray.push(date.getDate());
														itemArray.push(date.getMonth() + 1);
														itemArray.push(date.getFullYear());
														itemArray.push(date.getDay());
														itemArray.push(Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24));
													}
													else {
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
													}
												}
												else if (featureType == "TIME") {
													let value = item[feature.attribute.name];
													if (value != null && value != "") {
														value.split(":").forEach(item => itemArray.push(parseInt(item)));
													}
													else {
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
													}
												}
												else if (featureType == "TIMESTAMP") {
													let value = item[feature.attribute.name];
													if (value != null && value != "") {
														let date = new Date(value);
														itemArray.push(date.getTime() / 1000);
														itemArray.push(date.getDate());
														itemArray.push(date.getMonth() + 1);
														itemArray.push(date.getFullYear());
														itemArray.push(date.getDay());
														itemArray.push(Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24));
														itemArray.push(date.getHours());
														itemArray.push(date.getMinutes());
														itemArray.push(date.getSeconds());
													}
													else {
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
														itemArray.push(null);
													}
												}
												else if (featureType == "POINT") {
													let value = item[feature.attribute.name];
													if (value != null && value != "") {
														value.substr(1, value.length - 2).split(",").forEach(item => itemArray.push(parseFloat(item)));
													}
													else {
														itemArray.push(null);
														itemArray.push(null);
													}
												}
												else {
													console.log("Feature type " + featureType + " not supported yet");
												}
											});
											x.push(itemArray);
										});

										//console.log(x);
										
										const predictionQuery = {
												schemaName: entityModel.schema,
												entityName: entityModel.name,
												intelligenceModelName: intelligenceModel.name,
												X: x
										};
	
										let request = JSON.stringify(predictionQuery);
	
										fetch(this.props.context.baseUrl + "/automl/predict", {
											method: "POST",
											headers: {
												"Content-Type": "application/json"
											},
											body: request
										})
										.then(response => response.json())
										.then(json => {
											this.setState((state, props) => {
												let predictions = state.predictions;
												if (predictions == null) {
													predictions = {};
												}
												predictions[intelligenceModel.name] = json.y;
												return {
													predictions: predictions,
												};
											});
										});
									});
								});
							}
						}
						else {
							this.props.context.hideActivityIndicator();
						}
					})
					.catch(error => {
						console.log("!!!!! Retrying...");
						console.log(error);
						console.log(request);
						setTimeout(this.refreshDataThrottled(), 500);
					});
				}
			}
		}
		catch (error) {
			console.log(error);
			this.go("/login");
		}
	}
	
	eventStyleGetter(event, start, end, isSelected, highlighterEnabled, highlighterAttribute) {
		let style = {};
		if (highlighterEnabled) {
			if (event[highlighterAttribute.name] == null) {
				style.backgroundColor = this.props.theme.palette.secondary.main;
				style.color = this.props.theme.palette.secondary.contrastText;
			}
			else {
				style.backgroundColor = event[highlighterAttribute.name];
				style.color = this.props.theme.palette.getContrastText(event[highlighterAttribute.name]);
			}
		}
		return {
			style: style,
		}
	}
	
	// Render
	render() {
		// console.log(">> EntityList.render");
		// const { orderBy, orderDirection } = this.state;
		const { classes, t, theme } = this.props;
		
		if (this.state != null 
				&& this.props.context.model !== null
				&& this.state.currentEntity === this.props.entity
				&& this.state.data != null) {
			
			const selectedItems = this.state.data.filter(item => item._selected);
			const { orderBy, orderDirection } = this.props.context.state[this.props.entity];
			
			const model = this.props.context.model;
			const entityLocalModel = localModel.entities[this.props.entity];
			const entityModel = model.entities[this.props.entity];
			
			let attributes = this.state.attributes;
			
			let localAttributes = Object.values(entityLocalModel.attributes);
			
			let highlighterEnabled = localAttributes.filter(attribute => attribute.color && attribute.highlighter).length > 0;
			let calendarEnabled = localAttributes.filter(attribute => attribute.eventStart).length > 0;
			let mapEnabled = localAttributes.filter(attribute => attribute.pointInMap).length > 0;
			let galleryEnabled = localAttributes.filter(attribute => attribute.imageInGallery).length > 0;
			let diagramEnabled = this.props.entity == 'Models.Entity';
			
			let highlighterAttribute = null;
			if (highlighterEnabled) {
				highlighterAttribute = localAttributes.filter(attribute => attribute.color && attribute.highlighter)[0];
			}
			
			let mapAttribute = null;
			if (mapEnabled) {
				mapAttribute = localAttributes.filter(attribute => attribute.pointInMap)[0];
			}
			
			let galleryAttribute = null;
			if (galleryEnabled) {
				galleryAttribute = localAttributes.filter(attribute => attribute.imageInGallery)[0];
			}
			
			//console.log("Attributes: ");
			//console.log(attributes);
			
			if (this.searchInput.current != null) {
				if ((this.searchInput.current.value == null || this.searchInput.current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].searchCriteria != null) {
					this.searchInput.current.value = this.props.context.state[this.props.entity].searchCriteria;
				}
			}
			
			attributes
					.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
							|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
					.forEach(attribute => {
				
				if (!attribute.array
						&& attribute.referenceAttributeName !== undefined) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						if (this.basicFilterRefs[attribute.name].current.value == null && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
							this.basicFilterRefs[attribute.name].current.select.select.setValue(this.props.context.state[this.props.entity].basicFilters[attribute.name], 'select-option');
						}
					}
				}
				else if (attribute.enumType === undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						if ((this.basicFilterRefs[attribute.name].current.value == null || this.basicFilterRefs[attribute.name].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
							this.basicFilterRefs[attribute.name].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name];
						}
					}
				}
				else if (attribute.enumType !== undefined && (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")) {
					if (this.basicFilterRefs[attribute.name] != null && this.basicFilterRefs[attribute.name].current != null) {
						if ((this.basicFilterRefs[attribute.name].current.value == null || this.basicFilterRefs[attribute.name].current.value == "_blank") && this.props.context.state[this.props.entity].basicFilters[attribute.name] != null) {
							Object.values(this.basicFilterRefs[attribute.name].current.options).forEach(option => option.selected = this.props.context.state[this.props.entity].basicFilters[attribute.name] == option.value);
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "INTEGER"
							|| attribute.type === "SMALLINT"
							|| attribute.type === "BIGINT"
							|| attribute.type === "SERIAL"
							|| attribute.type === "DECIMAL"
							|| attribute.type === "DOUBLE_PRECISION"
							|| attribute.type === "REAL"
							|| attribute.type === "MONEY"
							|| attribute.type === "SMALLSERIAL"
							|| attribute.type === "BIGSERIAL")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "From"].current.value == null || this.basicFilterRefs[attribute.name + "From"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] != null) {
							this.basicFilterRefs[attribute.name + "From"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						}
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "To"].current.value == null || this.basicFilterRefs[attribute.name + "To"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] != null) {
							this.basicFilterRefs[attribute.name + "To"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						}
					}
				}
				else if (!attribute.array
						&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME")) {
					if (this.basicFilterRefs[attribute.name + "From"] != null && this.basicFilterRefs[attribute.name + "From"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "From"].current.value == null || this.basicFilterRefs[attribute.name + "From"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"] != null) {
							this.basicFilterRefs[attribute.name + "From"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "From"];
						}
					}
					if (this.basicFilterRefs[attribute.name + "To"] != null && this.basicFilterRefs[attribute.name + "To"].current != null) {
						if ((this.basicFilterRefs[attribute.name + "To"].current.value == null || this.basicFilterRefs[attribute.name + "To"].current.value == "") && this.props.context.state[this.props.entity] != null && this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"] != null) {
							this.basicFilterRefs[attribute.name + "To"].current.value = this.props.context.state[this.props.entity].basicFilters[attribute.name + "To"];
						}
					}
				}
			});
			
			if (this.props.context.state[this.props.entity].mode == "diagram") {
				//this.state.diagramModel.layers = this.state.diagramModel.getLayers().reverse();
				engine.setModel(this.state.diagramModel);
			}

			let questions = entityLocalModel.questions != null && Object.values(entityLocalModel.questions).filter(question => question.list);
			if (questions == null) {
				questions = [];
			}
			
			return this.props.context.model !== null && (
				<form onSubmit={
						(event) => {
							event.preventDefault(); 
							this.refreshData();
						}
				}>
					<Grid container>
						<Grid item xs={12} sm={questions.length > 0 ? 8 : 12} lg={questions.length > 0 ? 9 : 12} xl={questions.length > 0 ? 10 : 12}>
							<Paper square elevation={1}>
								<div>
									<Toolbar className={selectedItems.length === 0 ? classes.toolbar : classes.toolbar2}>
										<Grid container spacing={24} justify="flex-start" alignItems="center">
											<Grid item xs sm>
												{ selectedItems.length > 0 ? (
													<Typography variant="h6" color="inherit" noWrap>{selectedItems.length} {t('selected')}</Typography>
												) : (localModel.entities[entityModel.schema + "." + entityModel.name].icon != null ? (
														<div style={{display: "flex", alignItems: "center"}}>
															<Icon>{localModel.entities[entityModel.schema + "." + entityModel.name].icon}</Icon>
															<Typography variant="h6" color="inherit" style={{flexGrow: "1", paddingLeft: "10px"}}>{t('e.' + this.props.entity + '.pluralName')}</Typography>
														</div>
													)
													: (
														<Typography variant="h6" color="inherit">{t('e.' + this.props.entity + '.pluralName')}</Typography>
													)
												)}
											</Grid>
											
											{ selectedItems.length > 0 ? (
											<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
												{
													this.state.attributes.filter(attribute => entityLocalModel.attributes[attribute.name].action && entityLocalModel.attributes[attribute.name].actionVisibleInToolbar).map(attribute => (
														<Tooltip key={attribute.name + "ToolbarButton"} title={t('e.' + this.props.entity + '.a.' + attribute.name)} disableFocusListener>
															<span>
																<IconButton 
																		data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-" + attribute.name + "-button"}
                                                                        aria-label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
																		classes={{
																			root: classes.action,
																			disabled: classes.disabledAction,
																		}}
																		disabled={this.props.editionDisabled || selectedItems.length !== 1 || selectedItems[0][attribute.name] == null || selectedItems[0][attribute.name] == ""}
																		onClick={event => this.handleActionClick(event, selectedItems[0], attribute)}
																>
																	<Icon>{(entityLocalModel.attributes[attribute.name].actionIcon != null ? entityLocalModel.attributes[attribute.name].actionIcon : "warning")}</Icon>
																</IconButton>
															</span>
														</Tooltip>
													))
												}
												<Tooltip title={t('view')} disableFocusListener>
													<span>
														<IconButton 
                                                                data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-view-button"}
																aria-label={t('view')} 
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																disabled={this.props.editionDisabled || selectedItems.length !== 1}
																onClick={this.handleViewClick}>
															<DescriptionIcon/>
														</IconButton>
													</span>
												</Tooltip>
												{ (model.super || model.entities[this.props.entity].privileges["UPDATE"] !== undefined) && (
													<Tooltip title={t('edit')} disableFocusListener>
														<span>
															<IconButton 
                                                                    data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-edit-button"}
																	aria-label={t('edit')}
																	classes={{
																		root: classes.action,
																		disabled: classes.disabledAction,
																	}}
																	disabled={this.props.editionDisabled || selectedItems.length !== 1 || (this.props.entity == 'Models.EntityAttribute' && selectedItems[0]["name"] == "id")}
																	onClick={this.handleEditClick}>
																<EditIcon/>
															</IconButton>
														</span>
													</Tooltip>
												)}
												{ (model.super || model.entities[this.props.entity].privileges["DELETE"] !== undefined) && (
													<Tooltip title={t('delete')} disableFocusListener>
														<span>
															<IconButton 
                                                                    data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-delete-button"}
																	aria-label={t('delete')} 
																	classes={{
																		root: classes.action,
																		disabled: classes.disabledAction,
																	}}
																	disabled={this.props.editionDisabled || this.props.entity == 'Models.EntityAttribute' && selectedItems.some(obj => obj["name"] == "id")}
																	onClick={this.handleDeleteClick}>
																<DeleteIcon/>
															</IconButton>
														</span>
													</Tooltip>
												)}
												{(model.super && !this.props.entity.startsWith("Models.") &&
													<Tooltip title={t('download')} disableFocusListener>
														<span>
															<IconButton 
                                                                    data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-download-button"}
																	aria-label={t('download')} 
																	classes={{
																		root: classes.action,
																		disabled: classes.disabledAction,
																	}}
																	onClick={this.handleDownloadClick}>
																<DownloadIcon/>
															</IconButton>
														</span>
													</Tooltip>
												)}
												{(!this.props.entity.startsWith("Models.") &&
														entityLocalModel.exportToCSVEnabled &&
													<Tooltip title={t('exportToCSV')} disableFocusListener>
														<span>
															<IconButton 
                                                                    data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-exportToCSV-button"}
																	aria-label={t('exportToCSV')} 
																	classes={{
																		root: classes.action,
																		disabled: classes.disabledAction,
																	}}
																	onClick={this.handleExportToCSVClick}>
																<DownloadOutlinedIcon/>
															</IconButton>
														</span>
													</Tooltip>
												)}
											</Grid>
										) : (
											<>
												<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
													{
														Object.keys(entityLocalModel.attributes)
																.filter(attributeName => entityLocalModel.attributes[attributeName].filteredWhenEmpty)
																.map(attributeName => (
																		<FormControlLabel key={attributeName + "Filter"}
																			control={
																					<Checkbox color="secondary" 
																						onChange={(event, checked) => this.handleCheckboxChange(event, checked, attributeName)} 
																						checked={this.props.context.state[this.props.entity].basicFilters[attributeName]}
																					/>
																			}
																			label={t('e.' + this.props.entity + '.pluralName') + " " + t('with') + " " + t('e.' + this.props.entity.split(".")[0] + "." + attributeName.split("ListVia")[0] + '.pluralName').toLowerCase()}/>
																))
													}
													{
														(calendarEnabled
															|| mapEnabled
															|| galleryEnabled
															|| diagramEnabled)
															&&
														<div className={classes.toggleContainer}>
															<ToggleButtonGroup selected value={this.props.context.state[this.props.entity].mode} exclusive onChange={this.handleChangeMode}>
																<ToggleButton data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-tableView-button"} value="table">
																	<Tooltip title={t('tableView')} disableFocusListener>
																		<ListIcon/>
																	</Tooltip>
																</ToggleButton>
																{
																	calendarEnabled
																			&&
																	<ToggleButton data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-calendarView-button"} value="calendar">
																		<Tooltip title={t('calendarView')} disableFocusListener>
																			<EventIcon/>
																		</Tooltip>
																	</ToggleButton>
																}
																{
																	mapEnabled
																			&&
																	<ToggleButton data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-mapView-button"} value="map">
																		<Tooltip title={t('mapView')} disableFocusListener>
																			<MapIcon/>
																		</Tooltip>
																	</ToggleButton>
																}
																{
																	galleryEnabled
																			&&
																	<ToggleButton data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-galleryView-button"} value="gallery">
																		<Tooltip title={t('galleryView')} disableFocusListener>
																			<GalleryIcon/>
																		</Tooltip>
																	</ToggleButton>
																}
																{
																	diagramEnabled
																			&&
																	<ToggleButton data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-diagramView-button"} value="diagram">
																		<Tooltip title={t('diagramView')} disableFocusListener>
																			<DiagramIcon/>
																		</Tooltip>
																	</ToggleButton>
																}
															</ToggleButtonGroup>
														</div>
													}
													{
														localAttributes.filter(attribute => attribute.basicFilter).length > 0
															&&
														<div className={classes.toggleContainer}>
															<Tooltip title={this.props.context.state[this.props.entity].basicFiltersEnabled ? t('disableBasicFilters') : t('enableBasicFilters')} disableFocusListener>
																<ToggleButtonGroup selected value={this.props.context.state[this.props.entity].basicFiltersEnabled} exclusive onChange={this.handleToggleBasicFilters}>
																	<ToggleButton data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-filtersApplied-button"} value={true} disabled={this.props.disabled}>
																		<FilterListIcon/>{(this.state.filtersApplied && ("*"))}
																	</ToggleButton>
																</ToggleButtonGroup>
															</Tooltip>
														</div>
													}
													{
														model.edition == 'Enterprise'
																&& entityLocalModel.intelligenceModels != null
																&&
														<div className={classes.toggleContainer}>
															<Tooltip title={this.props.context.state[this.props.entity].intelligenceEnabled ? t('disableIntelligence') : t('enableIntelligence')} disableFocusListener>
																<ToggleButtonGroup selected value={this.props.context.state[this.props.entity].intelligenceEnabled} exclusive onChange={this.handleToggleIntelligence}>
																	<ToggleButton data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-intelligenceEnabled-button"} value={true} disabled={this.props.disabled}>
																		<MemoryIcon/>
																	</ToggleButton>
																</ToggleButtonGroup>
															</Tooltip>
														</div>
													}
													{(model.super && !this.props.entity.startsWith("Models.") &&
														<Tooltip title={t('download')} disableFocusListener>
															<span>
																<IconButton 
                                                                        data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-download-button"}
																		disabled={this.props.disabled}
																		aria-label={t('download')} 
																		onClick={this.handleDownloadClick}>
																	<DownloadIcon/>
																</IconButton>
															</span>
														</Tooltip>
													)}
													{(!this.props.entity.startsWith("Models.") &&
															entityLocalModel.exportToCSVEnabled &&
														<Tooltip title={t('exportToCSV')} disableFocusListener>
															<span>
																<IconButton 
                                                                        data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-exportToCSV-button"}
																		disabled={this.props.disabled}
																		aria-label={t('exportToCSV')} 
																		onClick={this.handleExportToCSVClick}>
																	<DownloadOutlinedIcon/>
																</IconButton>
															</span>
														</Tooltip>
													)}
												</Grid>
												{ this.state.searchEnabled && (
													<Grid item xs sm style={{flexGrow: 0, textAlign: "end", whiteSpace: "nowrap"}}>
														<Tooltip title={t('searchHowTo')} disableFocusListener>
															<Input inputProps={{"data-qa": this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-searchInput-input"}} onKeyDown={this.handleSearchKeyDown} style={{width: "200px"}} disabled={this.props.disabled} inputRef={this.searchInput} placeholder={t('search') + "..."} autoFocus={this.props.main}/>
														</Tooltip>
														<Tooltip title={t('search')} disableFocusListener>
															<span>
																<IconButton 
                                                                        data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-search-button"}
																		disabled={this.props.disabled}
																		aria-label={t('search')} 
																		type="submit"
																>
																	<SearchIcon/>
																</IconButton>
															</span>
														</Tooltip>
														<Tooltip title={t('clearCriteria')} disableFocusListener>
															<span>
																<IconButton 
                                                                        data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-clearCriteria-button"}
																		disabled={this.props.disabled}
																		aria-label={t('clearCriteria')} 
																		onClick={this.handleClearCriteriaClick}>
																	<ClearIcon/>
																</IconButton>
															</span>
														</Tooltip>
													</Grid>
												)}
											</>
										)}
										
										{
											this.props.context.state[this.props.entity].mode == "diagram"
													&&
												<Tooltip title={t('zoomToFit')} disableFocusListener>
													<Fab data-qa={this.props.entity + "-zoom-to-fit"}
															onClick={event => engine.zoomToFit()}
															className={classes.fab2}
													>
														<ZoomToFitIcon/>
													</Fab>
												</Tooltip>
										}
										{
											(
												model.super 
														&& this.props.entity != "Models.EntityReference"
														&& this.props.entity != "Models.EntityReferenceAttribute"
														|| model.entities[this.props.entity].privileges != null && model.entities[this.props.entity].privileges["INSERT"] !== undefined) 
														&& ((this.props.main && 
													<Tooltip title={t('new')} disableFocusListener>
														<div>
															<Button data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-create-button"}
																	classes={{
																		root: (selectedItems.length > 0 ? classes.action : ''),
																		disabled: classes.disabledAction,
																	}}
																	disabled={this.props.editionDisabled || this.props.disabled}
																	aria-label={t('new')} 
																	color="secondary"
																	variant="outlined"
																	style={{borderRadius: "5px"}}
																	disableRipple
																	onClick={this.handleNewClick}>
																<AddIcon/>
																{t('new')}
															</Button>
														</div>
													</Tooltip>
												) || (
													<Tooltip title={t('new')} disableFocusListener>
														<div>
															<Button data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-create-button"}
																	classes={{
																		root: (selectedItems.length > 0 ? classes.action : ''),
																		disabled: classes.disabledAction,
																	}}
																	disabled={this.props.editionDisabled || this.props.disabled}
																	aria-label={t('new')} 
																	color="secondary"
																	variant="outlined"
																	style={{borderRadius: "5px"}}
																	disableRipple
																	onClick={this.handleNewClick}>
																<AddIcon/>
																{t('new')}
															</Button>
														</div>
													</Tooltip>
												)
											)
										}
										</Grid>
									</Toolbar>
								</div>
								{
									<div style={{display: (this.props.context.state[this.props.entity].basicFiltersEnabled ? "" : "none")}}>
										<Grid container style={{"padding": this.props.theme.spacing.unit * 3}}>
											<Grid item xs={12} sm={(this.state.searchEnabled ? 12 : 6)}>
												<Typography variant="subtitle2" 
														className={classes.formGroup} 
														color="inherit" 
														noWrap>
													{t('searchCriteria')}
												</Typography>
											</Grid>
											{ !this.state.searchEnabled && (
												<Grid item xs={12} sm={6} style={{textAlign: "end", whiteSpace: "nowrap"}}>
													<Tooltip title={t('search')} disableFocusListener>
														<span>
															<IconButton 
                                                                    data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-search-button"}
																	disabled={this.props.disabled}
																	aria-label={t('search')}
																	type="submit"
															>
																<SearchIcon/>
															</IconButton>
														</span>
													</Tooltip>
													<Tooltip title={t('clearCriteria')} disableFocusListener>
														<span>
															<IconButton 
                                                                    data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-clearCriteria-button"}
		      														disabled={this.props.disabled}
																	aria-label={t('clearCriteria')} 
																	onClick={this.handleClearCriteriaClick}>
																<ClearIcon/>
															</IconButton>
														</span>
													</Tooltip>
												</Grid>
											)}
											{
												attributes
														.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].basicFilter
																|| entityLocalModel.attributes[attribute.referenceAttributeName] != null && entityLocalModel.attributes[attribute.referenceAttributeName].basicFilter)
														.map(attribute => 
														
													// Todos los campos que no son referencias inversas
													(
														attribute.incomingReferenceAttributeName === undefined 
																&& 
														(
															// Campos de texto enumerado con un único valor o con múltiples valores
															(
																(attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
																		&& attribute.enumType !== undefined 
																		&& 
																	<Grid key={attribute.name} item xs={12} sm={6}
																		style={{paddingTop: "30px", paddingRight: "20px"}}
																	>
																		<FormControl fullWidth>
																			<InputLabel	shrink>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
																			<br/>
																			<Select 
                                                                                    data-qa={attribute.name + "-input"}
																					inputRef={this.basicFilterRefs[attribute.name]}
																					native
																			>
																				<option key="_blank" value="_blank">{t('anyValue')}</option>
																				<option key="_null" value="_null">{t('notSpecified')}</option>
																				{ 
																					Object.values(attribute.enumType.values).map(value => (
																						<option key={value} value={value}>{t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + value)}</option>
																					))
																				}
																			</Select>
																		</FormControl>
																	</Grid>
															)
															||
															
															// Campos de códigos de barras
															(
																entityLocalModel.attributes[attribute.name].type === 'BARCODE'
																		&& 
																	<Grid key={attribute.name} item xs={12} sm={6}
																		style={{paddingTop: "30px", paddingRight: "20px"}}
																	>
																		<Barcode
																				inputRef={this.basicFilterRefs[attribute.name]}
																				label={t('e.' + this.props.entity + '.a.' + attribute.name)} 
																				barcodeType={entityLocalModel.attributes[attribute.name].barcodeType}
																				disabled={!this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"]}
																				checked={this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"]}
																				onChange={(event, checked) => {
																					this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"] = checked;
																					this.setState((state, props) => {
																						return {
																							// No se utiliza, pero sirve para forzar el refresco de la pantalla
																							basicFilters: this.props.context.state[this.props.entity].basicFilters,
																						};
																					});
																				}}
																		/>
																	</Grid>
															)
															||
															
															// Campos de texto sencillos con un único valor o con múltiples valores
															(
																!attribute.rich
																		&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
																		&& attribute.enumType === undefined 
																		&& entityLocalModel.attributes[attribute.name].type != 'BARCODE'
																		&& 
																	<Grid key={attribute.name} item xs={12} sm={6}
																		style={{paddingTop: "20px", paddingRight: "20px"}}
																	>
																		<TextField
                                                                                inputProps={{
                                                                                    "data-qa": attribute.name + "-input"
                                                                                }}
																				inputRef={this.basicFilterRefs[attribute.name]}
																				label={t('e.' + this.props.entity + '.a.' + attribute.name)}
																				fullWidth
																				InputLabelProps={{shrink: true}}
																				disabled={!this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"]}
																				InputProps={{
																					endAdornment: (
																						<Checkbox
																							style={{padding: 0}}
																							disabled={false}
																							checked={this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"]}
																							onChange={(event, checked) => {
																								this.props.context.state[this.props.entity].basicFilters[attribute.name + "Enabled"] = checked;
																								this.setState((state, props) => {
																									return {
																										// No se utiliza, pero sirve para forzar el refresco de la pantalla
																										basicFilters: this.props.context.state[this.props.entity].basicFilters,
																									};
																								});
																							}}
																						/>
																					)
																				}}
																		/>
																	</Grid>
															)
															||
															
															// Campos booleanos
															(
																!attribute.array
																		&& attribute.type === "BOOLEAN" 
																		&& 
																	<Grid key={attribute.name} item xs={12} sm={6}
																		style={{paddingTop: "20px", paddingRight: "20px"}}
																	>
																		<FormControlLabel
																				control={
																						<Checkbox color="secondary" 
																								indeterminate={this.props.context.state[this.props.entity].basicFilters[attribute.name] == null}
																								onChange={() => this.handleBasicFilterCheckboxChange(attribute.name)} 
																								checked={this.props.context.state[this.props.entity].basicFilters[attribute.name] === true}
																						/>
																				}
																				label={t('e.' + this.props.entity + '.a.' + attribute.name)}/>
																	</Grid>
															)
															||
															
															// Referencias a otras entidades, cardinalidad uno
															(
																!attribute.array
																		&& attribute.referenceAttributeName !== undefined
																		&&
																	<Grid key={attribute.name} item xs={12} sm={6}
																		style={{paddingTop: "20px", paddingRight: "20px"}}
																	>
																		<AutoComplete
																				entityName={Object.values(entityModel.references).filter(reference => reference.name === attribute.name)[0].referencedKey.entityName}
																				asyncRef={this.basicFilterRefs[attribute.name]}
																				label={t('e.' + this.props.entity + '.a.' + attribute.name)}
																		/>
																	</Grid>
															)			
															||
															
															// Campos de tipo entero con un único valor
															// Campos de tipo numérico con decimales con un único valor
															(
																attribute.referenceAttributeName === undefined
																		&& !attribute.array 
																		&& (attribute.type === "INTEGER"
																				|| attribute.type === "SMALLINT"
																				|| attribute.type === "BIGINT"
																				|| attribute.type === "SERIAL"
																				|| attribute.type === "DECIMAL"
																				|| attribute.type === "DOUBLE_PRECISION"
																				|| attribute.type === "REAL"
																				|| attribute.type === "MONEY"
																				|| attribute.type === "SMALLSERIAL"
																				|| attribute.type === "BIGSERIAL") 
																		&& 
																	<Grid key={attribute.name} item xs={12} sm={6}
																		style={{paddingTop: "20px", paddingRight: "20px"}}
																	>
																		<Grid container spacing={24}>
																			<Grid key={attribute.name + "From"} item xs={12} sm={6}>
																				<TextField 
																						inputRef={this.basicFilterRefs[attribute.name + "From"]}
																						label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Desde)"} 
																						fullWidth
																						inputProps={{
                                                                                            "data-qa": attribute.name + "-from-input",
                                                                                            min: attribute.min, 
                                                                                            max: attribute.max, 
                                                                                            step: attribute.step
                                                                                        }}
																						InputLabelProps={{shrink: true}}
																						type="number"
																						disabled={!this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]}
																						InputProps={{
																							endAdornment: (
																								<Checkbox
																									style={{padding: 0}}
																									disabled={false}
																									checked={this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]}
																									onChange={(event, checked) => {
																										this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"] = checked;
																										this.setState((state, props) => {
																											return {
																												// No se utiliza, pero sirve para forzar el refresco de la pantalla
																												basicFilters: this.props.context.state[this.props.entity].basicFilters,
																											};
																										});
																									}}
																								/>
																							)
																						}}
																				/>
																			</Grid>
																			<Grid key={attribute.name + "To"} item xs={12} sm={6}>
																				<TextField 
																						inputRef={this.basicFilterRefs[attribute.name + "To"]}
																						label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Hasta)"} 
																						fullWidth
																						inputProps={{
                                                                                            "data-qa": attribute.name + "-to-input",
                                                                                            min: attribute.min, 
                                                                                            max: attribute.max, 
                                                                                            step: attribute.step
                                                                                        }}
																						InputLabelProps={{shrink: true}}
																						type="number"
																						disabled={!this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]}
																						InputProps={{
																							endAdornment: (
																								<Checkbox
																									style={{padding: 0}}
																									disabled={false}
																									checked={this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]}
																									onChange={(event, checked) => {
																										this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"] = checked;
																										this.setState((state, props) => {
																											return {
																												// No se utiliza, pero sirve para forzar el refresco de la pantalla
																												basicFilters: this.props.context.state[this.props.entity].basicFilters,
																											};
																										});
																									}}
																								/>
																							)
																						}}
																				/>
																			</Grid>
																		</Grid>
																	</Grid>
															) 
															||
															
															// Campos de tipo fecha con un único valor
															(
																!attribute.array 
																		&& (attribute.type === "DATE" || attribute.type === "TIMESTAMP" || attribute.type === "TIME") 
																		&& 
																	<Grid key={attribute.name} item xs={12} sm={6}
																		style={{paddingTop: "20px", paddingRight: "20px"}}
																	>
																		<Grid container spacing={24}>
																			<Grid key={attribute.name + "From"} item xs={12} sm={6}>
																				<TextField 
                                                                                        inputProps={{
                                                                                            "data-qa": attribute.name + "-from-input"
                                                                                        }}
																						inputRef={this.basicFilterRefs[attribute.name + "From"]}
																						label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Desde)"} 
																						fullWidth
																						InputLabelProps={{shrink: true}}
																						type={(attribute.type === "DATE" ? "date" : (attribute.type === "TIME" ? "time" : "datetime-local"))}
																						disabled={!this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]}
																						InputProps={{
																							endAdornment: (
																								<Checkbox
																									style={{padding: 0}}
																									disabled={false}
																									checked={this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"]}
																									onChange={(event, checked) => {
																										this.props.context.state[this.props.entity].basicFilters[attribute.name + "FromEnabled"] = checked;
																										this.setState((state, props) => {
																											return {
																												// No se utiliza, pero sirve para forzar el refresco de la pantalla
																												basicFilters: this.props.context.state[this.props.entity].basicFilters,
																											};
																										});
																									}}
																								/>
																							)
																						}}
																				/>
																			</Grid>
																			<Grid key={attribute.name + "To"} item xs={12} sm={6}>
																				<TextField 
                                                                                        inputProps={{
                                                                                            "data-qa": attribute.name + "-to-input"
                                                                                        }}
																						inputRef={this.basicFilterRefs[attribute.name + "To"]}
																						label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (Hasta)"} 
																						fullWidth
																						InputLabelProps={{shrink: true}}
																						type={(attribute.type === "DATE" ? "date" : (attribute.type === "TIME" ? "time" : "datetime-local"))}
																						disabled={!this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]}
																						InputProps={{
																							endAdornment: (
																								<Checkbox
																									style={{padding: 0}}
																									disabled={false}
																									checked={this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"]}
																									onChange={(event, checked) => {
																										this.props.context.state[this.props.entity].basicFilters[attribute.name + "ToEnabled"] = checked;
																										this.setState((state, props) => {
																											return {
																												// No se utiliza, pero sirve para forzar el refresco de la pantalla
																												basicFilters: this.props.context.state[this.props.entity].basicFilters,
																											};
																										});
																									}}
																								/>
																							)
																						}}
																				/>
																			</Grid>
																		</Grid>
																	</Grid>
															)
														)
													)
												)
											}
											<Grid item xs={12} sm={12}
												style={{paddingTop: "20px"}}
											>
												<Button data-qa="applyFilter-button" variant="contained" type="submit">{t('applyFilter')}</Button>
											</Grid>
										</Grid>
									</div>
								}
								
								<div className={classes.root}>
								{
									(this.props.context.state[this.props.entity].mode === 'table' 
											&& ((this.state.data !== null && this.state.data.length > 0 
											&& 
										<Table className={classes.table}>
											<TableHead>
												<TableRow className={classes.tableHeader}>
													{
														<TableCell padding="checkbox" key="checkbox" className={classes.checkbox}>
															<Checkbox
																	inputProps={{
                                                                        "data-qa": this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-selectAll-button"
                                                                    }}
                                                                    indeterminate={selectedItems.length !== 0
																			&& selectedItems.length !== this.state.data.length}
																	disabled={this.props.editionDisabled}
																	checked={selectedItems.length === this.state.data.length}
																	onChange={(event, checked) => this.handleSelectAllClick(event, checked)}
															/>
														</TableCell>
													}
													{
														this.state.attributes
																.filter(attribute => 
																		(attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name] != null && (entityLocalModel.attributes[attribute.name].list === undefined || entityLocalModel.attributes[attribute.name].list) && (!entityLocalModel.attributes[attribute.name].action || !entityLocalModel.attributes[attribute.name].actionVisibleInToolbar))
																		|| (attribute.referenceAttributeName != null && entityLocalModel.attributes[attribute.referenceAttributeName] != null && (entityLocalModel.attributes[attribute.referenceAttributeName].list === undefined || entityLocalModel.attributes[attribute.referenceAttributeName].list))
																)
																.map(attribute =>
															(
																	!entityLocalModel.attributes[attribute.name].action
																		&& !attribute.array
																		&& (attribute.type === "TEXT"
																				|| attribute.type === "BOOLEAN"
																				|| attribute.type === "DATE"
																				|| attribute.type === "TIMESTAMP"
																				|| attribute.type === "VARCHAR"
																				|| attribute.type === "CHAR"
																				|| attribute.type === "TIME"
																				|| attribute.type === "INTERVAL"
																				|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
																				|| attribute.type === "TIME_WITH_TIME_ZONE"
																				|| attribute.type === "INTEGER"
																				|| attribute.type === "SERIAL"
																				|| attribute.type === "SMALLINT"
																				|| attribute.type === "BIGINT"
																				|| attribute.type === "SMALLSERIAL"
																				|| attribute.type === "BIGSERIAL"
																				|| attribute.type === "DECIMAL"
																				|| attribute.type === "MONEY"
																				|| attribute.type === "DOUBLE_PRECISION"
																				|| attribute.type === "REAL")
																		&& 
																	<TableCell  
                                                                            data-qa={this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-" + attribute.name + "-orderBy-button"}
																			style={{fontWeight: "bold", fontSize: "13px"}}
																			padding="dense"
																			key={attribute.name} 
																			align={((attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL') && !attribute.referenceAttributeName ? "right" : (attribute.type == "BOOLEAN" ? "center" : "left"))}
																			sortDirection={orderBy === attribute.name ? orderDirection : false}>
																		<TableSortLabel
																				active={orderBy === attribute.name}
																				direction={orderDirection}
																				onClick={(event) => this.handleSortClick(event, attribute, model, localModel)}>
																			{t('e.' + this.props.entity + '.a.' + attribute.name)}
																		</TableSortLabel>
																	</TableCell>
															) 
															|| entityLocalModel.attributes[attribute.name].action &&
															<TableCell 
																	padding="dense"
																	align="center"
																	key={attribute.name}>
															</TableCell>
															||
															<TableCell 
															 		style={{fontWeight: "bold", fontSize: "13px"}}
																	padding="dense"
																	align={((attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL') && !attribute.referenceAttributeName ? "right" : (attribute.type == "BOOLEAN" ? "center" : "left"))}
																	key={attribute.referenceAttributeName || attribute.name}>
																{t('e.' + this.props.entity + '.a.' + attribute.name)}
															</TableCell>
														)
													}
													{
														this.props.context.state[this.props.entity].intelligenceEnabled
																&&
															Object.values(entityLocalModel.intelligenceModels).map(intelligenceModel => 
																<TableCell 
																		style={{fontWeight: "bold", fontSize: "13px"}}
																		padding="dense"
																		key={intelligenceModel.name + "Prediction"}>
																	<>
																		<div>{intelligenceModel.name + " (" + t("prediction") + ")"}</div>
																		{(this.state.predictions == null || this.state.predictions[intelligenceModel.name] == null) && (<LinearProgress/>)}
																	</>
																</TableCell>
															)
													}
												</TableRow>
											</TableHead>
											<TableBody>
												{
													this.state.data.map((item, itemIndex) => {
														const isSelected = item._selected != null && item._selected;
														return (
															<TableRow
																	onClick={event => this.handleRowClick(event, item)}
																	role="checkbox"
																	tabIndex={-1}
																	key={item[this.state.keyAttribute.name]}
																	style={{backgroundColor: (highlighterEnabled ? (item[highlighterAttribute.name] != null ? item[highlighterAttribute.name] : "") : ""), color: (highlighterEnabled ? (item[highlighterAttribute.name] != null ? this.props.theme.palette.getContrastText(item[highlighterAttribute.name]) : "") : "")}}
																	className={this.props.editionDisabled ? classes.editionDisabledTableRow : classes.tableRow}>
																{
																	<TableCell padding="checkbox" key="checkbbox">
																		<Checkbox 
                                                                                inputProps={{
                                                                                    "data-qa": this.props.entity + (this.props.filterAttribute ? "-" + this.props.filterAttribute : "") + "-" + item[this.state.keyAttribute.name] + "-select-button"
                                                                                }}
																				checked={isSelected} 
																				disabled={this.props.editionDisabled}
																				onClick={event => this.handleCheckboxClick(event, item)}
																		/>
																	</TableCell>
																}
																{
																	this.state.attributes
																			.filter(attribute => 
																					(attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name] != null && (entityLocalModel.attributes[attribute.name].list === undefined || entityLocalModel.attributes[attribute.name].list) && (!entityLocalModel.attributes[attribute.name].action || !entityLocalModel.attributes[attribute.name].actionVisibleInToolbar))
																					|| (attribute.referenceAttributeName != null && entityLocalModel.attributes[attribute.referenceAttributeName] != null && (entityLocalModel.attributes[attribute.referenceAttributeName].list === undefined || entityLocalModel.attributes[attribute.referenceAttributeName].list))
																			)
																			.map(attribute => (
																		attribute.referenceAttributeName === undefined 
																				&& 
																			<TableCell 
																					padding="dense"
																					key={attribute.name} 
																					align={(entityLocalModel.attributes[attribute.name].action ? "center" : (attribute.type === 'INTEGER' || attribute.type === 'DECIMAL' || attribute.type === 'MONEY' || attribute.type === 'SERIAL' || attribute.type === 'SMALLINT' || attribute.type === 'BIGINT' || attribute.type === 'DOUBLE_PRECISION' || attribute.type === 'REAL' || attribute.type === 'SMALLSERIAL' || attribute.type === 'BIGSERIAL' ? "right" : (attribute.type == "BOOLEAN" ? "center" : "left")))}>
																				{
																					(
																						attribute.array
																								&& !entityLocalModel.attributes[attribute.name].action
																								&& attribute.enumType != null
																								&& item[attribute.name] != null
																								&&
																							<div>{item[attribute.name] == null ? "" : item[attribute.name].map(element => <Chip key={element} variant="outlined" icon={t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.icon.' + element).indexOf(".") == (-1) ? <Icon>{t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.icon.' + element)}</Icon> : null} style={{borderRadius: "5px", marginRight: "5px", marginTop: "5px", cursor: this.props.editionDisabled ? "default" : "pointer", ...t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.color.' + element).startsWith("#") && { backgroundColor: t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.color.' + element), color: this.props.theme.palette.getContrastText(t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.color.' + element))}}} label={t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + element)}/>)}</div>
																					)
																					||
																					(
																						attribute.array 
																								&& !entityLocalModel.attributes[attribute.name].action
																								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
																								&&
																							<div>{item[attribute.name] == null ? "" : item[attribute.name].join(", ")}</div>
																					)
																					||
																					(
																						!attribute.array
																								&& !entityLocalModel.attributes[attribute.name].action
																								&& attribute.enumType != null
																								&& item[attribute.name] != null
																								&&
																							<Chip variant="outlined" icon={t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.icon.' + item[attribute.name]).indexOf(".") == (-1) ? <Icon>{t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.icon.' + item[attribute.name])}</Icon> : null} style={{borderRadius: "5px", cursor: this.props.editionDisabled ? "default" : "pointer", ...t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.color.' + item[attribute.name]).startsWith("#") && { backgroundColor: t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.color.' + item[attribute.name]), color: this.props.theme.palette.getContrastText(t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.color.' + item[attribute.name]))}}} label={t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + item[attribute.name])}/>
																					)
																					||
																					(
																						!attribute.array
																								&& !entityLocalModel.attributes[attribute.name].action
																								&& attribute.type === 'BOOLEAN'
																								&&
																							(
																								item[attribute.name] != null && !item[attribute.name] && <Icon>close</Icon>
																								|| item[attribute.name] && <Icon>done</Icon>
																							)
																					)
																					||
																					(
																						!attribute.array
																								&& !entityLocalModel.attributes[attribute.name].action
																								&& attribute.type === 'DATE'
																								&&
																							<div>{(item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleDateString())}</div>
																							/* Ojo con la fecha 02/02/0002, que JavaScript "se vuelve loco", aunque claro, hace 2000 años, ummm, igual es correcto */
																					)
																					||
																					(
																						!attribute.array
																								&& !entityLocalModel.attributes[attribute.name].action
																								&& attribute.type === 'TIMESTAMP'
																								&&
																							<div>{(item[attribute.name] == null ? "" : new Date(item[attribute.name]).toLocaleString())}</div>
																					)
																					||
																					(
																						entityLocalModel.attributes[attribute.name].type == "DOCUMENT"
																							&& (
																								!attribute.array
																										&& !entityLocalModel.attributes[attribute.name].action
																										&& item[attribute.name] != null 
																										&& item[attribute.name].thumbnail != null 
																										&& 
																									<img
																										onClick={event => this.handleCellClick(event, item, attribute, entityModel, entityLocalModel)}
																										className={classes.imageHover}
																										style={{backgroundSize: "10px 10px, 10px 10px, 10px 10px, 10px 10px", backgroundColor: "white", backgroundPosition: "0 0, 0 5px, 5px -5px, -5px 0px", backgroundImage: "linear-gradient(45deg,#c0c0c0 25%,transparent 25%), linear-gradient(-45deg,#c0c0c0 25%,transparent 25%), linear-gradient(45deg,transparent 75%,#c0c0c0 75%), linear-gradient(-45deg,transparent 75%,#c0c0c0 75%)", maxHeight: "48px", maxWidth: "96px"}} 
																										src={(item[attribute.name] == null ? "" : item[attribute.name].thumbnail)}/>
																								|| 
																									!attribute.array
																											&& !entityLocalModel.attributes[attribute.name].action
																											&& item[attribute.name] != null
																											&&
																									<Chip 
																										onClick={event => this.handleCellClick(event, item, attribute, entityModel, entityLocalModel)}
																										variant="outlined" 
																										icon={<Icon>description</Icon>} 
																										style={{borderRadius: "5px", marginRight: "5px", marginTop: "5px", cursor: this.props.editionDisabled ? "default" : "pointer" }} label={(item[attribute.name] == null ? "" : item[attribute.name].name)}
																									/>
																							)
																					)
																					||
																					(
																						!attribute.array
																								&& !entityLocalModel.attributes[attribute.name].action
																								&& (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL')
																								&&
																							(entityLocalModel.attributes[attribute.name].step == null 
																									&& <div style={{ whiteSpace: "nowrap" }}>{(item[attribute.name] == null ? "" : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator }) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix))}</div>
																									|| <div style={{ whiteSpace: "nowrap" }}>{(item[attribute.name] == null ? "" : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator, minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)}) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix))}</div>
																							)
																					)
																					|| entityLocalModel.attributes[attribute.name].action 
																						&& item[attribute.name] != null 
																						&& item[attribute.name] != "" 
																						&&
																							<Button data-qa={ attribute.name + "-button" } style={{textWrap: "nowrap"}} variant="contained" onClick={event => this.handleActionClick(event, item, attribute)}>
																								{t('e.' + this.props.entity + '.a.' + attribute.name)}
																							</Button>
																					|| attribute.type === 'POINT'
																						&&
																							<div>{item[attribute.name] == null ? "" : "(" + String(Number(Number(item[attribute.name].substr(1, item[attribute.name].length - 2).split(",")[0]).toFixed(12))) + "," +  + String(Number(Number(item[attribute.name].substr(1, item[attribute.name].length - 2).split(",")[1]).toFixed(12))) + ")"}</div>
																					||
																					<div>{item[attribute.name]}</div>
																				}
																			</TableCell>
																		) 
																		|| 
																		(
																			<TableCell 
																					padding="dense"
																					key={attribute.referenceAttributeName}>
																				{
																					(
																						item[attribute.referenceAttributeName] != null 
																								&& !entityLocalModel.attributes[attribute.referenceAttributeName].linkDisabled
																								&& 
																							// Le pongo el href, aunque no lo use, al menos para evitar el warning. Por otra parte, tampoco funcionaría porque recarga...
																							<a href={"not-used-but-required"} className={classes.anchor} onClick={event => this.handleAnchorClick(event, item, attribute)}>
																								<Chip className={classes.hover} variant="outlined" icon={<Icon>link</Icon>} style={{borderRadius: "5px", marginRight: "5px", marginTop: "5px", cursor: this.props.editionDisabled ? "default" : "pointer" }} label={(item[attribute.referenceAttributeName] === null ? "" : this.getLabel(item[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName]))}/>
																							</a>
																					)
																					||
																					(
																						item[attribute.referenceAttributeName] != null 
																								&& entityLocalModel.attributes[attribute.referenceAttributeName].linkDisabled
																								&& item[attribute.referenceAttributeName] === null ? "" : this.getLabel(item[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName])
																					)
																				}
																			</TableCell>
																		)
																	)
																}
																{
																	this.props.context.state[this.props.entity].intelligenceEnabled
																			&&
																		Object.values(entityLocalModel.intelligenceModels).map(intelligenceModel => 
																			<TableCell 
																					padding="dense"
																					key={intelligenceModel.name + "Prediction"}>
																				<div>{this.state.predictions != null && this.state.predictions[intelligenceModel.name] != null && this.state.predictions[intelligenceModel.name][itemIndex]}</div>
																			</TableCell>
																		)
																}
															</TableRow>
														);
													})
												}
											</TableBody>
										</Table>
											) || (
										<Typography variant="subtitle1" className={classes.noData} color="inherit" noWrap>{t('noData')}</Typography>
											))
									)
									
									|| (this.props.context.state[this.props.entity].mode === 'calendar' 
											&& 
										<div className={classes.calendar}>
								    		<DnDCalendar
							    				defaultDate={(this.state.data.filter(item => localAttributes.filter(attribute => attribute.eventStart).length > 0 && item[localAttributes.filter(attribute => attribute.eventStart)[0].name] != null).length > 0 ? new Date(this.state.data[0][localAttributes.filter(attribute => attribute.eventStart)[0].name]) : new Date())}
								    			className={classes.bigCalendar}
							            		dayLayoutAlgorithm="no-overlap"
							    				culture={t('calendarCulture')}
							    				localizer={localizer}
							    				events={this.state.data.filter(item => localAttributes.filter(attribute => attribute.eventStart).length > 0 && item[localAttributes.filter(attribute => attribute.eventStart)[0].name] != null)}
							    				defaultView={(localAttributes.filter(attribute => attribute.eventStart)[0].type == "TIMESTAMP" ? "week" : "month")}
							    				views={["month", "week", "day"]}
							    				titleAccessor={(item) => this.getLabel(item, entityModel, entityLocalModel)}
							    				allDayAccessor={(item) => (localAttributes.filter(attribute => attribute.eventStart)[0].type == "DATE")}
							    				startAccessor={(item) => (localAttributes.filter(attribute => attribute.eventStart).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventStart)[0].name]) : null)}
							    				endAccessor={(item) => (localAttributes.filter(attribute => attribute.eventEnd).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventEnd)[0].name]) : (localAttributes.filter(attribute => attribute.eventStart).length > 0 ? new Date(item[localAttributes.filter(attribute => attribute.eventStart)[0].name]) : null))}
							    				onNavigate={(date, view) => {
							    					console.log(date);
							    					console.log(view);
							    				}}
							    				onSelectEvent={(item) => this.go("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view", true, this.props.entity)}
							    				showMultiDayTimes={false}
							    				eventPropGetter={(event, start, end, isSelected) => this.eventStyleGetter(event, start, end, isSelected, highlighterEnabled, highlighterAttribute)}
							    				messages={{
							    					date: t('calendarDate'),
							    					time: t('calendarTime'),
							    					event: t('calendarEvent'),
							    					allDay: t('calendarAllDay'),
							    					week: t('calendarWeek'),
							    					work_week: t('calendarWorkWeek'),
							    					day: t('calendarDay'),
							    					month: t('calendarMonth'),
							    					previous: t('calendarPrevious'),
							    					next: t('calendarNext'),
							    					yesterday: t('calendarYesterday'),
							    					tomorrow: t('calendarTomorrow'),
							    					today: t('calendarToday'),
							    					agenda: t('calendarAgenda'),
							    				}}
								    			onEventResize={this.handleEditFromCalendar}
								    			onEventDrop={this.handleEditFromCalendar}
								    			selectable
								    			onSelectSlot={this.handleNewFromCalendar}
								    		/>
								    	</div>
								    )
									
									|| (this.props.context.state[this.props.entity].mode === 'map' 
											&&
										<Map zoom={13}
												style={{height: "calc(100vh - 176px)"}}
												minZoom={2}
												maxZoom={19}
												center={[this.state.latitude, this.state.longitude]}
												maxBounds={[[-90, -180], [90, 180]]}
												zoomControl={true}
										>
											<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"/>
											
											{/*
											<ImageOverlay 
													url="http://localhost:3000/008-Colón-27-Hogar-PB.png" 
													bounds={[[-90, -180], [90, 180]]}
													attribution={"El Corte Inglés"}
											/>
											*/}
											
											{
												this.state.data.map(item => item[mapAttribute.name] != null && (
													<Marker onclick={event => this.handleRowClick(event.originalEvent, item)} key={item[mapAttribute.name]} position={[item[mapAttribute.name].substr(1, item[mapAttribute.name].length - 2).split(",")[0], item[mapAttribute.name].substr(1, item[mapAttribute.name].length - 2).split(",")[1]]}>
													</Marker>
												))
											}
										</Map>
									)
									
									|| (this.props.context.state[this.props.entity].mode === 'gallery' 
											&& ((this.state.data.filter(item => item[galleryAttribute.name] != null).length > 0 
											&& 
										
										<div style={{padding: 20}}>
											<Grid 
													spacing={8}
													container 
													direction="row"
													justify="flex-start"
													alignItems="flex-end"
													style={{listStyle: "none"}}
											>
												{
													this.state.data.map(item => item[galleryAttribute.name] != null && 
														<Grid key={item[this.state.keyAttribute.name]} item style={{padding: 10}}>
															<GridListTile onClick={() => !this.props.editionDisabled ? this.go("/admin/" + this.props.entity + '/' + item[this.state.keyAttribute.name] + "/view", true, this.props.entity) : null} style={{cursor: this.props.editionDisabled ? "default" : "pointer"}}>
																<img style={{maxWidth: "640px", maxHeight: "360px"}} src={item[galleryAttribute.name].thumbnail} alt={this.getLabel(item, entityModel, entityLocalModel)}/>
																<GridListTileBar
																		title={this.getLabel(item, entityModel, entityLocalModel)}
																/>
																{/*subtitle={<span>{item[galleryAttribute.name].name}</span>}*/}
															</GridListTile>
														</Grid>
													)
												}
											</Grid>
										</div>
									) || (
										<Typography variant="subtitle1" className={classes.noData} color="inherit" noWrap>{t('noDataWithImage')}</Typography>
									)))
									
									|| (this.props.context.state[this.props.entity].mode === 'diagram' 
										&& 
											<BlockPageScroll>
												<CanvasWidget className={classes.canvasWidget} engine={engine}/>
											</BlockPageScroll>
									)
								}
								</div>
							</Paper>
						</Grid>
						
						{
							questions.length > 0 
								&&
							<Grid item xs={12} sm={4} lg={3} xl={2}>
								{
									questions.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).map(question => (
										this.state.questions != null
												&& this.state.questions[question.name] != null
												&&
											<div key={question.name}>
												<Paper square elevation={1}>
													<div>
														<Toolbar className={classes.toolbar}>
															<Grid container spacing={24} justify="flex-start" alignItems="center">
																<Grid item xs sm>
																	<Typography variant="h6" color="inherit" noWrap>{t('e.' + this.props.entity + '.questions.' + question.name)}</Typography>
																</Grid>
																
																<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
																	<Tooltip title={t('fullscreen')} disableFocusListener>
																		<div>
																			<IconButton
                                                                                    data-qa="fullscreen-button"
																					aria-label={t('fullscreen')} 
																					onClick={event => document.getElementById(question.name).requestFullscreen()}>
																				<FullscreenIcon/>
																			</IconButton>
																		</div>
																	</Tooltip>
																</Grid>
															</Grid>
														</Toolbar>
													</div>
													<div>
														<Grid item>
															<iframe id={question.name} allowFullScreen scrolling="no" className={classes.iframe} src={this.props.context.baseUrl + "/dashboards/embed/question/" + this.state.questions[question.name].url + "#bordered=false&titled=false"}></iframe>
														</Grid>
													</div>
												</Paper>
											</div>
									))
								}
							</Grid>
						}
					</Grid>
					
					{
						(this.state.scrollEnabled //!this.props.main && this.state.scrollEnabled Se deshabilita el scroll automático para facilitar el acceso al scroll horizontal.
								&&
							<Button
                                    data-qa="getMoreData-button"
									variant="contained" 
									className={classes.expandMoreButton}
									onClick={this.expandMore}>
								{t('getMoreData')}
							</Button>
						)
					}
					
					<Dialog style={{zIndex: 10000}}
							open={this.state && this.state.deleteConfirmationDialogOpened}
							onClose={event => this.setState({deleteConfirmationDialogOpened: false})}>
						<DialogTitle>{t('deleteConfirmation')}</DialogTitle>
						<DialogContent>
							<DialogContentText>{t('deleteQuestion') + " " + selectedItems.length + " " + t('selected') + "?"}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button data-qa="cancel-button" onClick={event => this.setState({deleteConfirmationDialogOpened: false})}>{t('cancel')}</Button>
							&nbsp;
							<Button data-qa="delete-button" onClick={this.handleDeleteDebounced}>{t('delete')}</Button>
						</DialogActions>
					</Dialog>
					<Dialog 
							scroll="paper"
							open={this.state.previewOpen} 
							onClose={this.onPreviewCloseDialog} 
							classes={{ paper: classes.previewDialogPaper }}>
						<DialogTitle>
							<IconButton data-qa="close-button" aria-label="close" className={classes.closeButton} onClick={this.onPreviewCloseDialog}>
								<CloseIcon />
					        </IconButton>
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<DocViewer 
									className={classes.docViewer}
									pluginRenderers={DocViewerRenderers}
									documents={[{uri: this.state.previewUrl}]}
									config={{
										header: {
											disableHeader: true,
											disableFileName: true,
										}
									}}
							/>
						</DialogContent>
					</Dialog>
					
					<Snackbar
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							autoHideDuration={5000}
							onClose={event => this.setState({ messageOpened: false })}
							open={this.state && this.state.messageOpened}>
						<SnackbarContent
								className={this.state.messageError ? classes.snackbarError : classes.snackbar}
								message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
						/>
					</Snackbar>
				</form>
			);
		}
		else if (this.state.messageError) {
			return (
			<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					autoHideDuration={5000}
					onClose={event => this.setState({ messageOpened: false })}
					open={this.state && this.state.messageOpened}>
				<SnackbarContent
						className={this.state.messageError ? classes.snackbarError : classes.snackbar}
						message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
				/>
			</Snackbar>
			);
		}
		else {
			return null;
		}
	}
}

EntityList.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	entity: PropTypes.string.isRequired,
	mode: PropTypes.string, // "table", "calendar", "map", "gallery"
	filterAttribute: PropTypes.string,
	filterValue: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	editionDisabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(EntityList)));
